import React, { useState, useEffect } from 'react';
import "./reports.css";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArchiveIcon from '@material-ui/icons/Archive';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as apiClient from "../../common/apiClient";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: 0,
        minWidth: 300,
    },
});

export const ReportStudentTracking = (props: any) => {
    const [scholarno, setScholarNo] = useState("");
    const [reportData, setReportData] = useState([]);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const classes = useStyles();
    const handleChange = (event: any) => {
        setScholarNo(event.target.value);
        //console.log(event.target.value);
    };
    
    const getReportData = () => {
        apiClient.student_tracking_track_student(scholarno).then((res: any) => {
            console.log(res);
            setReportData(res.result);
        }).catch((err: any) => {
            console.log(err);
        });
    }
    useEffect(() => {

    }, []);
    return (
        <div className="report-container">
            <div className="report-header">
                <div className="report-title">Student Tracking Report</div>
                <div className="report-actions">
                    {/* <ButtonGroup variant="contained" color="primary">
                        <Button startIcon={<ArchiveIcon />}>CSV</Button>
                        <Button startIcon={<PictureAsPdfIcon />}>PDF</Button>
                    </ButtonGroup> */}
                </div>
            </div>
            <div className="report-filters">
                <div className="report-filter-title">
                    Filters
                </div>
                <div className="report-filter-input">
                    <FormControl className={classes.formControl} variant="outlined" size="small">
                        <TextField type="text" label="Scholar Number" size="small" variant="outlined" onChange={handleChange} />
                    </FormControl>
                    {/* <TextField type="date" label="From Date" size="small" variant="outlined" />
                    <TextField type="date" label="To Date" size="small" variant="outlined" /> */}

                </div>
                <div className="report-filter-action">
                    <Button variant="contained" color="primary"
                        onClick={() => { getReportData(); }}
                        startIcon={<AssessmentIcon />}>Generate Report</Button>
                </div>
            </div>
            <div className="report-data-table">
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Topic</TableCell>
                                <TableCell>Topic note</TableCell>
                                <TableCell>View Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData && reportData.map((row: any) => (
                                <TableRow key={row.Code}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell>{row.subject}</TableCell>
                                    <TableCell>{row.topic}</TableCell>
                                    <TableCell>{row.topic_note}</TableCell>
                                    <TableCell>{row.view_date}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
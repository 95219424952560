// dev config
// export const APIUrl = 'http://localhost/leaderboard/index.php/api/';
// // export const APIUrl = 'http://localhost/work/leaderboard/api/index.php/api/';
// export const ENDPOINT = 'http://127.0.0.1:3009';
// export const PATH = '';
export const WhiteBoardUrl = 'https://leaderboard.pidlay.com/boards';
// production config
export const APIUrl = 'https://braintonica.com/leaderboard/services/index.php/api/';
//export const ENDPOINT = "https://api.pidlay.com/";
export const ENDPOINT = "";
export const PATH = "/socket.io";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { ThemeProvider } from '@material-ui/styles';
import { Location } from 'history';
import PropTypes from 'prop-types';
import { useLogin, useNotify, useTranslate } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { Notification } from 'react-admin';
import { Field, withTypes } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import * as apiClient from '../common/apiClient';
import AppLoading from '../common/appLoading';
import { lightTheme } from './themes';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#b2defd',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 300,
    marginTop: '0em',
    height: 380,
    borderRadius: '5px 0px 0px 5px'
  },
  avatar: {
    margin: '0em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
    height: 101
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  register: {
    padding: '1em',
    color: '#000',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  registerText: {
    marginTop: '20px',
    width: 300
  },
  input: {
    marginTop: '1em'
  },
  passwordInput: {
    marginTop: '1em',
    position: 'relative'
  },
  actions: {
    padding: '0 1em 1em 1em'
  },
  paper: {
    position: 'absolute',
    width: 650,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));
interface IMeta {
  touched: boolean;
  error: Error;
}

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    variant='outlined'
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

interface FormValues {
  email?: string;
  password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = ({ location }: { location: Location }) => {
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [org, setOrg] = useState<any>(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [open, setOpen] = useState(false); // TURN ON OFF VIDEO

  const [modalStyle] = React.useState(getModalStyle);

  const translate = useTranslate();
  const classes = useStyles({});
  const notify = useNotify();
  const login = useLogin();
  let history = useHistory();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    let data = {
      username: auth.email,
      password: auth.password
    };
    apiClient
      .loginUser(data)
      .then((response) => {
        setLoading(false);
        if (response.status === 'success') {
          let objUser = response.result;

          let designation = '';
          if (objUser.employee && objUser.employee.designation)
            designation = objUser.employee.designation;
          else designation = objUser.role;

          localStorage.setItem('userid', objUser.id);
          localStorage.setItem('username', objUser.username);
          localStorage.setItem('password', objUser.password);
          localStorage.setItem('displayName', objUser.display_name);
          localStorage.setItem('token', objUser.token);
          localStorage.setItem('role', objUser.role);
          localStorage.setItem('reference_id', objUser.reference_id);
          localStorage.setItem('designation', designation);

          history.push('/');
        } else {
          localStorage.clear();
          notify(response.message, 'warning');
        }
      })
      .catch((err) => {
        setLoading(false);
        notify(err, 'warning');
      });
  };
  const getOrgData = () => {
    apiClient
      .getOrgData()
      .then((res) => {
        setOrg(res.result);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getOrgData();
  }, []);
  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.email) {
      errors.email = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const onExiTokenSuccess = () => {
    setIsLoading(false);
  };
  const onExiTokenFailure = () => {
    setIsLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const body = (
    <div style={modalStyle} className='video-model'>
      <h2 className='intro-modal-title'>Online Classes Introduction</h2>
      <div id='simple-modal-description'>
        <Button
          className='close-button'
          variant='contained'
          color='primary'
          onClick={handleClose}
          fullWidth
        >
          Close And Continue To Login
        </Button>
        <iframe
          className='intro-video-player'
          src='https://player.vimeo.com/video/441511800'
          allow='autoplay; fullscreen'
        ></iframe>
        <Button
          className='close-button'
          variant='contained'
          color='primary'
          onClick={handleClose}
          fullWidth
        >
          Close And Continue To Login
        </Button>
      </div>
    </div>
  );
  return (
    <>
      {isLoading && (
        <AppLoading
          onExiTokenFailure={onExiTokenFailure}
          onExiTokenSuccess={onExiTokenSuccess}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.main}>
              <Card className='login-card'>
                {!org ||
                  (!org.logo_url && (
                    <div className={classes.hint}>
                      <LockIcon />
                    </div>
                  ))}
                {org && org.logo_url && (
                  <div className='logo-container'>
                    <img src={org.logo_url} className='org-logo' />
                  </div>
                )}
                <div className={classes.form}>
                  <div className={classes.input}>
                    <Field
                      autoFocus
                      name='email'
                      // @ts-ignore
                      component={renderInput}
                      label='User name'
                      disabled={loading}
                    />
                  </div>
                  <div className={classes.passwordInput}>
                    <Field
                      name='password'
                      // @ts-ignore
                      component={renderInput}
                      label={translate('ra.auth.password')}
                      type={isPasswordVisible ? 'text' : 'password'}
                      disabled={loading}
                    />
                    <span
                      onClick={() => {
                        if (isPasswordVisible) setIsPasswordVisible(false);
                        else setIsPasswordVisible(true);
                      }}
                      className='password-eye'
                    >
                      {isPasswordVisible && <VisibilityIcon />}
                      {!isPasswordVisible && <VisibilityOffIcon />}
                    </span>
                  </div>
                </div>
                <CardActions className={classes.actions}>
                  <Button
                    variant='contained'
                    type='submit'
                    color='primary'
                    disabled={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress size={25} thickness={2} />}
                    {translate('ra.auth.sign_in')}
                  </Button>
                </CardActions>
              </Card>
              {
                <Card className='registerCard'>
                  <div className={classes.register}>
                    <div className={classes.avatar}>
                      {!org ||
                        (!org.logo_url && (
                          <Avatar className={classes.icon}>
                            <HowToRegIcon />
                          </Avatar>
                        ))}
                      <div className='org-logo-container'>
                        {org && org.name && (
                          <div className='org-name'>{org.name}</div>
                        )}
                        {org && org.address && (
                          <div className='org-address'>{org.address}</div>
                        )}
                      </div>
                    </div>
                    <div className={classes.registerText}>
                      <div>
                        <b>Step 1:</b> Type In User Name And Password In The Log
                        In Box And Then Select Sign In.{' '}
                      </div>
                      <div>Your User Name And Password Are: </div>
                      <ol>
                        <li>
                          User Name: (This information is personalized for each
                          organization){' '}
                        </li>
                        <li>Password: Your account password.</li>
                      </ol>
                      <div>
                        <b>Step 2:</b> Once You Have Entered In Your User Name
                        And Password, You Can Start Your Session.
                      </div>
                    </div>
                  </div>
                </Card>
              }
              <Notification />
            </div>
          </form>
        )}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        {body}
      </Modal>
    </>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles({}) hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createMuiTheme(lightTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;

import React, { useState, useEffect } from 'react';
import "./reports.css";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ArchiveIcon from '@material-ui/icons/Archive';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as apiClient from "../../common/apiClient";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    formControl: {
        margin: 0,
        minWidth: 300,
    },
});

export const ReportQuizDetails = (props: any) => {
    const [quizes, setQuizes] = useState([]);
    const [selectedQuiz, setSelectedQuiz] = useState<any>("");
    const [reportData, setReportData] = useState<any>(null);
    const [studentData, setStudentData] = useState<any>(null);

    const classes = useStyles();

    const getAllQuizes = () => {
        apiClient.get_all_quizes().then((res: any) => {
            //console.log(res);
            setQuizes(res.result);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const getScoreRangeTotal = (start, end) => {
        let total = parseInt(reportData.questions.total_questions) * 1000;
        let count = 0
        reportData.score.forEach(item => {
            let per = (item.score / total) * 100;
            //console.log(per);
            if (per >= start && per <= end)
                count++;
        });
        return count;
    }
    const getReportData = () => {
        apiClient.quiz_details_report(selectedQuiz).then((res: any) => {
            //console.log(res);
            setReportData(res.result);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    const getReportStudentData = (user_id) => {
        apiClient.quiz_student_details_report(selectedQuiz, user_id).then((res: any) => {
            console.log(res);
            setStudentData(res.result);
        }).catch((err: any) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getAllQuizes();
    }, []);
    return (
        <div className="report-container">
            <div className="report-header">
                <div className="report-title">Quiz Report</div>
                <div className="report-actions">
                    {/* <ButtonGroup variant="contained" color="primary">
                        <Button startIcon={<ArchiveIcon />}>CSV</Button>
                        <Button startIcon={<PictureAsPdfIcon />}>PDF</Button>
                    </ButtonGroup> */}
                </div>
            </div>
            <div className="report-filters">
                <div className="report-filter-title">
                    Filters
                </div>
                {/* <div className="report-filter-input">
                    <FormControl className={classes.formControl} variant="outlined" size="small">
                        <TextField type="text" label="Scholar Number" size="small" variant="outlined" onChange={handleChange} />
                    </FormControl>
                     <TextField type="date" label="From Date" size="small" variant="outlined" />
                    <TextField type="date" label="To Date" size="small" variant="outlined" /> 
                </div> */}


                <div className="report-filter-input">
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Select Quiz</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Employee" onChange={(e) => { setSelectedQuiz(e.target.value); }} value={selectedQuiz} >
                            <MenuItem value={0}></MenuItem>
                            {quizes && quizes.map((item: any, index) => {
                                return (<MenuItem key={index} value={item.id}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </div>

                <div className="report-filter-action">
                    <Button variant="contained" color="primary"
                        onClick={() => { getReportData(); }}
                        startIcon={<AssessmentIcon />}>Generate Report</Button>
                </div>
            </div>
            <div className='report-data-container'>
                <div className='quiz-analysis'>
                    <div className="report-data-table quiz-overview">
                        <TableContainer component={Paper}>
                            <div className='report-section-heading'>Quiz Analysis</div>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                {reportData && reportData.details && <TableBody>
                                    <TableRow>
                                        <TableCell>CLASS</TableCell>
                                        <TableCell>{reportData.details.class_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>SUBJECT</TableCell>
                                        <TableCell>{reportData.details.subject}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>TOPIC</TableCell>
                                        <TableCell>{reportData.details.name} {reportData.details.description}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>DATE</TableCell>
                                        <TableCell>{reportData.details.start_date}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>NO OF STUDENTS</TableCell>
                                        <TableCell>{reportData.details.scholars}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>MAXIMUM MARKS</TableCell>
                                        <TableCell>{parseInt(reportData.questions.total_questions) * 1000}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>HIGHEST SCORE</TableCell>
                                        <TableCell>{reportData.score[0].score}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>LOWEST SCORE</TableCell>
                                        <TableCell>{reportData.score[reportData.score.length - 1].score}</TableCell>
                                    </TableRow>

                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="report-data-table">
                        <TableContainer component={Paper}>
                            <div className='report-section-heading'>Overall Performance</div>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                {reportData && <TableBody>
                                    <TableRow >
                                        <TableCell>TOTAL PARTICIPANTS</TableCell>
                                        <TableCell>{reportData.details.scholars}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>SCORE RANGE 90-100%</TableCell>
                                        <TableCell>{getScoreRangeTotal(90, 100)}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>80-90%</TableCell>
                                        <TableCell>{getScoreRangeTotal(80, 89)}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>70-80%</TableCell>
                                        <TableCell>{getScoreRangeTotal(70, 79)}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>60-70%</TableCell>
                                        <TableCell>{getScoreRangeTotal(60, 69)}</TableCell>
                                    </TableRow>
                                    <TableRow >
                                        <TableCell>BELOW 60%</TableCell>
                                        <TableCell>{getScoreRangeTotal(0, 59)}</TableCell>
                                    </TableRow>
                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <div className="report-data-table quiz-student">
                    <TableContainer component={Paper}>
                        <div className='report-section-heading'>Student Details</div>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Student Name</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Student Report</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reportData && reportData.score && reportData.score.map((row: any, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {row.display_name}
                                        </TableCell>
                                        <TableCell>{row.score}</TableCell>
                                        <TableCell> <Button variant="contained" color="primary"
                                            onClick={() => { getReportStudentData(row.uid); }}>Details</Button></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </div>
            <div className='student-report-container'>
                <div className="report-data-table quiz-student">
                    <TableContainer component={Paper}>
                        <div className='report-section-heading'>Student Score Details</div>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>QUESTION NO</TableCell>
                                    <TableCell>SCORE</TableCell>
                                    <TableCell>ANSWER</TableCell>
                                    <TableCell>IS CORRECT</TableCell>
                                    <TableCell>TIME OF RESPONSE IN SECONDS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {studentData && studentData.map((row: any, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell>{row.score}</TableCell>
                                        <TableCell>{row.option_answer}</TableCell>
                                        <TableCell>{(row.score > 0) ? "YES" : "NO"}</TableCell>
                                        <TableCell>{parseInt(row.duration) / 1000}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </div>

        </div>
    )
}
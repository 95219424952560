import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
  NumberField,
  ImageField,
  EditButton,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import * as apiClient from '../../common/apiClient';

const TestFilter = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput source='chapter_name' alwaysOn />
    <TextInput source='topic' alwaysOn />
    <ReferenceInput source='class_id' reference='genere' link={false} alwaysOn>
      <SelectInput optionText='class_name' />
    </ReferenceInput>
    <ReferenceInput
      source='subject_id'
      reference='subject'
      link={false}
      alwaysOn
    >
      <SelectInput optionText='subject' />
    </ReferenceInput>
  </Filter>
);

export const QuestionList = (props) => {
  const role = localStorage.getItem('role');
  const isStudent = role == 'student';
  return (
    <List
      title='List of Questions'
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<TestFilter />}
    >
      <Datagrid rowClick={() => {}}>
        {!isStudent && <EditButton />}
        <ImageField source='question_img_url' />
        {/* <BooleanField source='has_multiple_answer' /> */}
        <NumberField source='duration' />
        <NumberField source='option_count' />
        <TextField source='chapter_name' />
        <TextField source='topic' />
        <TextField source='correct_option' />
        <ReferenceField source='class_id' reference='genere' link={false}>
          <TextField source='class_name' />
        </ReferenceField>
        <ReferenceField source='subject_id' reference='subject' link={false}>
          <TextField source='subject' />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

import React, { FC } from 'react';
import './style.css';
import { QuestionExplanationProps } from './types';
import { WhiteBoardUrl } from '../../../env';

export const QuestionExplanation: FC<QuestionExplanationProps> = ({
  onShowHideQuestionClick,
  question,
  showQuestionShowHide = false,
  userType = 'teacher'
}) => {
  return (
    <div className='explanation-container'>
      <iframe
        frameBorder='0'
        className='drawing-tool'
        src={`${WhiteBoardUrl}/${question.quiz_id}${question.question_id}${
          userType == 'student' ? '?hideMenu=true' : ''
        }`}
      ></iframe>
      {showQuestionShowHide && (
        <input
          style={{ alignSelf: 'flex-end' }}
          type='button'
          className='theme-button blue'
          value='Show/Hide Question'
          onClick={onShowHideQuestionClick}
        />
      )}
    </div>
  );
};

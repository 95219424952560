import { QuizList } from "./QuizList";
import { QuizEdit } from "./QuizEdit";
import { QuizCreate } from "./QuizCreate";

const role = localStorage.getItem('role');
const isStudent = role == 'student';
let quiz: any = {
    list: QuizList,
    create: QuizCreate,
    edit: QuizEdit
};
if (isStudent) {
    quiz = {
        list: QuizList,
    }
}
export default quiz;

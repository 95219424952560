import React, { useState, useCallback, useEffect } from 'react';
import {
    useRefresh,
    Create, TabbedForm, FormTab, TextInput, ReferenceInput, SimpleForm,
    SelectInput, ReferenceManyField, required, Datagrid, TextField
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles, Drawer } from '@material-ui/core';
import CDeleteButton from "../../common/CDeleteButton";
import { SectionCreatePopup } from "./SectionCreatePopup";
import { FormToolbar } from "../../common/FormToolbar";

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 350,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 0,
        width: 650,
        maxWidth: "100%",
        paddingBottom: 50
    },
}));

export const ClassCreate = props => {
    const myclasses = myStyles({});
    const refresh = useRefresh();
    //STATE
    const [isaddSecton, setIsAddSection] = useState(false);

    // PRIVATE METHODS
    const closeSectionForm = () => {
        setIsAddSection(false);
        refresh();
    }
    const onCancel = () => {
        setIsAddSection(false);
    }
    return (
        <React.Fragment>
            <Create title="Create Class" {...props}>
                <SimpleForm variant="outlined" redirect="list" toolbar={<FormToolbar {...props} />}>

                    {/* <TextInput source="id" fullWidth={true} /> */}
                    <ReferenceInput source="class_group_id" reference="classgroup" fullWidth={true} link={false} sort={{ field: 'id', order: 'ASC' }}>
                        <SelectInput optionText="class_group" />
                    </ReferenceInput>
                    <TextInput source="class_name" label="Class" fullWidth={true} validate={[required()]} />
                    <TextInput source="numeric_equivalent" label="Numeric Equivalent" fullWidth={true} validate={[required()]} />

                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}
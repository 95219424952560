import React from 'react';
import { Edit, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";

export const SectionCreatePopup = props => {
    const defaultValues = { class_id: props.class_id, };

    const handleSubmit = (formdata) => {
        let data = {
            class_id: props.class_id,
            section: formdata.section,
        };
        apiClient.addEntiyRecord("section", data).then(() => {
            props.onClose();
        });
    }
    return (
        <Create title="Section Edit" {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                initialValues={defaultValues}
                variant="outlined">
                <TextInput source="section" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}
import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import { ChangePassword } from './screens/changepassword/ChangePassword';
import { ReportStudentTracking } from './screens/reports/StudentTracking';
import { ReportQuizDetails } from './screens/reports/ReportQuizDetails';
import { StudentPresentation } from './screens/student-presentation/index';
import { Tutorial } from './screens/Tutorial';
import { Presentation } from './screens/presentation';
import { QuizQuestion } from './screens/quiz-question';
import { QuizScholar } from './screens/quiz-scholar';
import { FacultyDiscussion } from './screens/faculty-discussion';
import { StudentDiscussion } from './screens/student-discussion';
import { Attendance } from './screens/uploadattendance1/AttendanceList';

export default [
  <Route exact path='/configuration' render={() => <Configuration />} />,
  <Route
    exact
    path='/changepassword'
    render={(props) => <ChangePassword {...props} />}
  />,
  <Route
    exact
    path='/reportstudenttracking'
    render={(props) => <ReportStudentTracking {...props} />}
  />,
  <Route
    exact
    path='/reportquizdetails'
    render={(props) => <ReportQuizDetails {...props} />}
  />,
  
  <Route
    exact
    path='/tutorial'
    render={(props) => <Tutorial {...props} />}
    noLayout
  />,
  <Route
    exact
    path='/presentation/:id'
    render={(props) => <Presentation {...props} />}
    noLayout
  />,
  <Route
    exact
    path='/student-presentation/:id'
    render={(props) => <StudentPresentation {...props} />}
    noLayout
  />,
  <Route
    exact
    path='/quiz-question/:id'
    render={(props) => <QuizQuestion {...props} />}
  />,
  <Route
  exact
  path='/quiz-scholar/:id'
  render={(props) => <QuizScholar {...props} />}
/>,
  <Route
    exact
    path='/faculty-discussion/:id'
    render={(props) => <FacultyDiscussion {...props} />}
    noLayout
  />,
  <Route
    exact
    path='/student-discussion/:id'
    render={(props) => <StudentDiscussion {...props} />}
    noLayout
  />
  ,
  <Route exact path="/uploadattendance" render={() => <Attendance />} />,
  
];

import React from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import {FormToolbar} from "../../common/FormToolbar";

export const SectionEdit = props => {
    return (
        <Edit title="Section Edit" {...props}>
            <SimpleForm   toolbar={<FormToolbar {...props} />}
            variant="outlined">
                {/* <TextInput source="id" /> */}
                <ReferenceInput source="class_id" reference="genere" fullWidth={true}>
                    <SelectInput optionText="class_name" />
                </ReferenceInput>
                <TextInput source="section" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Edit>
    );
}
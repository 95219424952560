import { Theme, useMediaQuery } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React, { FC, useState } from 'react';
import { DashboardMenuItem, MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import SubMenu from './SubMenu';

type MenuName =
  | 'menuConfiguration'
  | 'menuFacultyOperation'
  | 'menuStudentOperation'
  | 'mnuReport';

interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    menuConfiguration: true,
    menuFacultyOperation: true,
    menuStudentOperation: true,
    mnuReport:false
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  let role = localStorage.getItem('role');
  let isSuperAdmin = role == 'super administrator';
  let isAdministrator = role == 'administrator';
  let isPrincipal = role == 'principal';
  let isFaculty = role == 'faculty';
  let isStudent = role == 'student';

  return (
    <div>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
      <Divider />
      {(isSuperAdmin || isAdministrator) && (
        <>
          <MenuItemLink
            to={`/quiz`}
            primaryText='Quiz'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/discussion`}
            primaryText='Discussion'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/question`}
            primaryText='Question'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <SubMenu
            handleToggle={() => handleToggle('mnuReport')}
            isOpen={state.menuConfiguration}
            sidebarIsOpen={open}
            name='Reports'
            icon={<SettingsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/reportstudenttracking`}
              primaryText='Student Tracking'
              leftIcon={<AssessmentOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/reportquizdetails`}
              primaryText='Quiz Details'
              leftIcon={<AssessmentOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            handleToggle={() => handleToggle('menuConfiguration')}
            isOpen={state.menuConfiguration}
            sidebarIsOpen={open}
            name='Configuration'
            icon={<SettingsIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/classgroup`}
              primaryText='Class Group'
              leftIcon={<AssessmentOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/genere`}
              primaryText='Classes'
              leftIcon={<DashboardOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/uploadattendance`}
              primaryText='Upload Attendance'
              leftIcon={<VpnKeyIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/section`}
              primaryText='Sections'
              leftIcon={<AccountTreeOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/subject`}
              primaryText='Subject'
              leftIcon={<ImportContactsOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/employee`}
              primaryText='Employee'
              leftIcon={<PermContactCalendarOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/scholar`}
              primaryText='Scholars'
              leftIcon={<SchoolOutlinedIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </>
      )}
      {isStudent && (
        <>
          <MenuItemLink
            to={`/quiz`}
            primaryText='Quiz'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          <MenuItemLink
            to={`/discussion`}
            primaryText='Discussion'
            leftIcon={<AssessmentOutlinedIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {/* <SubMenu
            handleToggle={() => handleToggle('menuStudentOperation')}
            isOpen={state.menuStudentOperation}
            sidebarIsOpen={open}
            name='Operations'
            icon={<SchoolOutlinedIcon />}
            dense={dense}
          >
            <MenuItemLink
              to={`/meeting_schedule`}
              primaryText='Live Class'
              leftIcon={<LiveTvIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
              to={`/timeline`}
              primaryText='Study Timeline'
              leftIcon={<AccessTimeIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/assignment`}
              primaryText='Assignments'
              leftIcon={<CloudUploadIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <Divider />

            <MenuItemLink
              to={`/tutorial`}
              primaryText='Exam-Demo'
              leftIcon={<TimelapseIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <Divider />
            <MenuItemLink
              to={`/time_limit_assignment`}
              primaryText='Test Assignments'
              leftIcon={<TimelapseIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />

            <MenuItemLink
                        to={`/time_limit_assignment_submission`}
                        primaryText="Time Limit Assignmen Submissiont"
                        leftIcon={<AccessAlarmIcon />}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                        dense={dense}
                    />
            <Divider />
            <MenuItemLink
              to={`/contentquery`}
              primaryText='View Queries'
              leftIcon={<ContactSupportIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu> */}
        </>
      )}
      <Divider />
      <MenuItemLink
        to={`/changepassword`}
        primaryText='Change Password'
        leftIcon={<VpnKeyIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {logout}
      <Divider />
    </div>
  );
};

export default Menu;

import React, { useState, useCallback, useEffect } from 'react';
import {FormDataConsumer,
    Edit, Create, SimpleForm, TextInput, DateInput,
    ReferenceInput, SelectInput, required, BooleanInput
} from 'react-admin';
import { containerStyles } from "../../common/formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import {ClassSectionFields} from "./ClassSectionFields";

export const ScholarCreate = props => {
    const [selectedClass, setSelectedClass] = useState(0);
    const classes = containerStyles({});
    return (
        <Create title="Create Scholar" {...props}>
            <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                variant="outlined">
                {/* <TextInput source="id"  /> */}
                <TextInput source="scholar_no" label="Scholar No." fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="session" label="Academic Session" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="name" label="Name" fullWidth={true} formClassName={classes.first_inline_input} />
                <DateInput source="dob" label="Date of birth" fullWidth={true} formClassName={classes.last_inline_input} />
                {/* <TextInput source="gender" label="Gender" fullWidth={true} formClassName={classes.first_inline_input} /> */}
                <SelectInput source="gender" label="Gender" fullWidth={true} formClassName={classes.first_inline_input}
                    choices={[
                        { id: 'Male', name: 'Male' },
                        { id: 'Female', name: 'Female' }
                    ]} />
                <TextInput source="father" label="School" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="mother" label="City" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="alert_mobile_no" label="Alert Mobile No" fullWidth={true} formClassName={classes.last_inline_input} />
                <FormDataConsumer>
                        {formDataProps => (
                            <ClassSectionFields {...formDataProps} />
                        )}
                    </FormDataConsumer>
                {/* <ReferenceInput source="class_id" reference="genere"
                    onChange={(ev) => { setSelectedClass(ev.target.value); }}
                    fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="class_name" fullWidth={true} />
                </ReferenceInput>
                {selectedClass > 0 && <ReferenceInput source="section_id" filter={{ class_id: selectedClass }}
                    reference="section" fullWidth={true}
                    formClassName={classes.last_inline_input}>
                    <SelectInput optionText="section" />
                </ReferenceInput>} */}

                <BooleanInput source='is_active' label="Is Active" initialValue={true} />
            </SimpleForm>
        </Create>
    );
}
import React, { FC } from 'react';
import { ScholarItemProps } from './types';
import './style.css';

export const ScholarItem: FC<ScholarItemProps> = ({
  scholar,
  add,
  remove
}) => {
  return (
    <div className='question-item'>
      {scholar.scholar_no} - {scholar.name}
      {add && (
        <button
          className='btn-add'
          type='button'
          onClick={() => {
            add(scholar.id);
          }}
        >
          Add
        </button>
      )}
      {remove && (
        <button
          className='btn-add'
          type='button'
          onClick={() => {
            remove(scholar.scholar_id);
          }}
        >
          Remove
        </button>
      )}
    </div>
  );
};

import React, { FC } from 'react';
import { QuestionProps } from './types';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import './style.css';

export const QuestionView: FC<QuestionProps> = ({
  question,
  onTimerComplete,
  showTimer
}) => {
  return (
    <>
      <img className='question-img' src={question.question_img_url} />
      {question && question.duration > 0 && showTimer && (
        <div className='timer-container'>
          <CountdownCircleTimer
            onComplete={onTimerComplete}
            isPlaying
            duration={parseInt(question.duration.toString())}
            size={60}
            colors={[
              ['#004777', 0.34],
              ['#F7B801', 0.33],
              ['#A30000', 0.33]
            ]}
            strokeWidth={5}
          >
            {({ remainingTime }) => remainingTime}
          </CountdownCircleTimer>
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CountBlock from "./CountBlock";
import UpcomingAssignmentList from "./student/upcoming_assignment";
import Paper from '@material-ui/core/Paper';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentScholarList from './teacher/assignment_status';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Attendance} from './admin/attendance';


export interface IHeader {
    SubjectCount: number;
    TestCount: number;
    QuestionCount: number;
    CandidateCount: number;
}

const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
    },
});

const Dashboard = () => {
    const [role, setRole] = useState("Student");
    const [assignments, setAssignments] = useState([]);
    const [assignmentCode, setAssignmentCode] = useState("");
    const [assignmentStatus, setAssignmentStatus] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);
    const [allClasses, setAllClasses] = useState([]);
    const [allAssignments, setAllAssignments] = useState([]);
    const [scholarAssignmentId, setScholarAssignmentId] = useState("");
    const [scholarClassId, setScholarClassId] = useState(0);
    const [assignmentClassId, setAssignmentClassId] = useState(0);
    const [scholarsData, setScholarsData] = useState([]);
    const [userData, setUserData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loginCount, setLoginCount] = useState(0);
    const styles = useStyles({});


    const loadAllClasses = () => {
        apiClient.get_all_classes_for_employee().then(res => {
            if (res.status == "success") {
                setAllClasses(res.result);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const LoadAllAssignments = (class_id) => {
        apiClient.get_all_assignments_for_class_from_faculty(class_id).then(res => {
            console.log(res);
            if (res.status == "success") {
                setAllAssignments(res.result);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const onScholarClassChange = (class_id) => {
        setScholarClassId(class_id);
        if (class_id != 0) {
            LoadAllAssignments(class_id);
        }
    }

    const getStudentAssignment = () => {
        if (role != 'student')
            return;
        apiClient.getStudentAssignment().then(res => {
            console.log(res);
            if (res.status == "success") {
                setAssignments(res.result);
            }

        }).catch(err => {
            console.log(err);
        });
    }
    const getTeacherAssignmentStatus = () => {
        setLoading(true);
        apiClient.get_scholars_for_assignment(scholarClassId, scholarAssignmentId, assignmentStatus).then(res => {
            //console.log(res);
            setScholarsData(res.result);
            setLoading(false);
        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    const getAllUsers = () => {
        apiClient.get_all_users().then(res => {
            setUserData(res.result);
        }).catch(err => {
            console.log(err);
        });
    }

    const handleSubmit = (item) => {
        
        let data = {
            username: item.username,
            password: item.password
        }
        apiClient.loginUser(data).then(response => {
            console.log(response.status);
            console.log(response.result);
            //setLoginCount(loginCount + 1);
           // handleSubmit();
        }).catch(err => {
            console.log(err);
        });

    };
    const startLogin = () => {
        userData.forEach((item:any, index:number) => {
            handleSubmit(item);
        });
        // setInterval(() => {
        //     let lc = loginCount + 1;
        //     setLoginCount(lc);
            // handleSubmit();
        // }, 3000);
    }

    useEffect(() => {
        let role = localStorage.getItem("role");
        
        setRole(role!);
        getStudentAssignment();
        loadAllClasses();
    }, []);
    const isTeacher = (role == "faculty" || role == "principal");
    const isAdmin = (role == "super administrator" || role == "principal"  || role =="administrator");
    
    return (
        <div style={{ paddingTop: 20, }}>
            {isAdmin && 
            <div className={styles.flexColumn}>
                <Paper elevation={3} >
                    <Attendance data={[]} />
                </Paper>
           </div>
            }
            {(role == "student") &&
                <div className={styles.flexColumn}>
                    <Paper elevation={3} >
                        <h2 style={{ textAlign: "center" }}>Upcoming Quiz</h2>
                        {(role == "student") && assignments && <UpcomingAssignmentList data={assignments} />}
                    </Paper>
                </div>
            }
        </div>
    );
}

export default Dashboard;

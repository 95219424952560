import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const ClassList = props => {
    return (
        <React.Fragment>
            <List title="List of Classes" {...props}>
                <Datagrid rowClick="edit">
                    {/* <TextField source="id" /> */}
                    <ReferenceField source="class_group_id" reference="classgroup" link={false}>
                        <TextField source="class_group" />
                    </ReferenceField>
                    <TextField source="class_name" label="Class" />
                    <TextField source="numeric_equivalent" label="Numeric Equivalent" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}
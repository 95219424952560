import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { filter, orderBy } from 'lodash';
import { QuestionModel, QuestionScore, QuizModel, UserScore } from '../types';
import { QuestionView } from './question';
import { QuestionLeaderBoard } from './question-leaderboard';
import './style.css';
import { useEffect } from 'react';
import { UserScoreBoard } from './user-score-board';
import * as apiClient from '../../common/apiClient';
import socketIOClient, { Socket } from 'socket.io-client';
import { ENDPOINT, PATH } from '../../env';
import { StartQuestion } from './start-question';
import { QuestionExplanation } from './question-explanation';
import logo from '../../assets/logo.png';
import { useHistory } from 'react-router-dom';
import { UserLeaderBoard } from "./user-leaderboard";
interface RouteParams {
  id: number;
}
const socket = socketIOClient(ENDPOINT, {
  path: PATH
});
export const Presentation = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const [quiz, setQuiz] = useState<QuizModel | null>(null);
  const [questionList, setQuestionList] = useState<QuestionModel[]>([]);
  const [startQuestions, setStartQuestions] = useState<boolean>(false);
  const [showQuestion, setShowQuestion] = useState<boolean>(false);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [showResultButton, setShowResultButton] = useState<boolean>(false);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [showExplanationButton, setShowExplanationButton] =
    useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<number>(0);
  const [showNexQuestionButton, setShowNexQuestionButton] =
    useState<boolean>(false);
  const [showBackQuestionButton, setShowBackQuestionButton] =
    useState<boolean>(false);
  const [showQLeaderBoard, setQShowLeaderBoard] = useState<boolean>(false);
  const [showScoreBoard, setShowScoreBoard] = useState<boolean>(false);
  const [showScoreBoardButton, setShowScoreBoardButton] =
    useState<boolean>(false);
  const [collapseQuestion, setCollapseQuestion] = useState<boolean>(false);
  const [questionScoreList, setQuestionScoreList] = useState<QuestionScore[]>(
    []
  );
  const [quizUserAnswerList, setQuizUserAnswerList] = useState<UserScore[]>([]);
  const setIsConnected = useState<boolean>(false)[1];

  const userId = localStorage.getItem('userid');
  const team_name = `TEAM_${id}`;

  const joinTeam = () => {
    console.log(socket, '===joinTeam socket====');
    if (!socket) return;
    const data = {
      team_name: team_name,
      member: {
        user_id: userId
      }
    };
    socket.emit('join-team', data);
  };

  const quizQuestions: QuestionModel[] = filter(
    questionList,
    (item: QuestionModel) => {
      return item.quiz_id && item.quiz_id == id;
    }
  );
  const sortedQuizQuestions: QuestionModel[] =
    quizQuestions.length > 0
      ? orderBy(quizQuestions, 'questionOrder', 'asc')
      : [];
  const totalQuestions = sortedQuizQuestions.length;
  const questionData =
    sortedQuizQuestions.length > 0
      ? sortedQuizQuestions[questionNumber]
      : undefined;

  useEffect(() => {
    initSocketConnection();
  }, []);

  useEffect(() => {
    if (!startQuestions) return;
    const data = {
      team_name: team_name,
      question: questionData
    };
    setShowTimer(true);
    setShowQuestion(true);
    socket.emit('on-question-start', data);
    apiClient.updateCurrentQuestion(id, questionData?.question_id!);
  }, [startQuestions]);


  const displayQuizDetails = () => {
    const data = {
      team_name: team_name,
      quiz: quiz
    };
    socket.emit('on-show-quiz-details', data);
  }

  const getQuiz = (id: number) => {
    apiClient
      .getQuiz(id)
      .then((res) => {
        if (res.status == 'success') {
          setQuiz(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    apiClient
      .getQuizQuestion(id)
      .then((res) => {
        if (res.status == 'success') {
          setQuestionList(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getQuiz(id);
  }, [id]);

  useEffect(() => {
    if (showQLeaderBoard) setQShowLeaderBoard(false);
    if (showScoreBoard) setShowScoreBoard(false);
    if (questionNumber === 0) return;
    const data = {
      team_name: team_name,
      question: questionData
    };
    //console.log(questionData, '====questionData==');
    socket.emit('on-question-start', data);
    apiClient.updateCurrentQuestion(id, questionData?.question_id!);
  }, [questionNumber]);

  useEffect(() => {
    if (!showQLeaderBoard || !questionData) return;
    apiClient
      .getQuizQuestionLeaderBoard(questionData.question_id, questionData.quiz_id)
      .then((res) => {
        if (res.status == 'success') {
          setQuestionScoreList(res.result);
          const data = {
            team_name: team_name,
            questionScoreList: res.result
          };
          socket.emit('on-show-result', data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showQLeaderBoard]);

  useEffect(() => {
    if (!showScoreBoard || !questionData) return;
    apiClient
      .getQuizUserLeaderBoard(questionData.quiz_id)
      .then((res) => {
        if (res.status == 'success') {
          setQuizUserAnswerList(res.result);
          const data = {
            team_name: team_name,
            quizUserAnswerList: res.result
          };
          socket.emit('on-show-score-board', data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showScoreBoard]);

  const onTimerComplete = () => {
    apiClient
      .updateQuestionScore(id, questionData?.question_id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    if (showTimer) setShowTimer(false);
    if (!showNexQuestionButton) setShowNexQuestionButton(true);
    if (!showResultButton) setShowResultButton(true);
    if (!showExplanationButton) setShowExplanationButton(true);
    if (!showScoreBoardButton) setShowScoreBoardButton(true);
    const data = {
      team_name: team_name,
      question: questionData
    };
    socket.emit('on-question-stop', data);
    apiClient.updateCurrentQuestion(id, 0);
  };

  const onShowHideQuestionClick = () => {
    setCollapseQuestion(!collapseQuestion);
    const data = {
      team_name: team_name,
      collapseQuestion: !collapseQuestion
    };
    socket.emit('on-question-show-hide', data);
  };

  const moveToQuestion = (qNumber) => {
    setStartQuestions(!startQuestions);
    setQuestionNumber(qNumber - 1);
    if (showNexQuestionButton) setShowNexQuestionButton(false);
    if (!showQuestion) setShowQuestion(true);
    if (!showTimer) setShowTimer(true);
    if (showExplanationButton) setShowExplanationButton(false);
    if (showResultButton) setShowResultButton(false);
    if (showScoreBoardButton) setShowScoreBoardButton(false);
    if (showBackQuestionButton) setShowBackQuestionButton(false);
    if (showExplanation) setShowExplanation(false);
  }
  const OnNextQuestionClick = () => {
    setQuestionNumber(questionNumber + 1);
    if (showNexQuestionButton) setShowNexQuestionButton(false);
    if (!showQuestion) setShowQuestion(true);
    if (!showTimer) setShowTimer(true);
    if (showExplanationButton) setShowExplanationButton(false);
    if (showResultButton) setShowResultButton(false);
    if (showScoreBoardButton) setShowScoreBoardButton(false);
    if (showBackQuestionButton) setShowBackQuestionButton(false);
    if (showExplanation) setShowExplanation(false);
    // Emit the data to socket for Other users //
  };

  const onScoreClick = () => {
    if (showQuestion) setShowQuestion(false);
    if (showExplanationButton) setShowExplanationButton(false);
    if (showResultButton) setShowResultButton(false);
    if (showScoreBoardButton) setShowScoreBoardButton(false);
    if (!showBackQuestionButton) setShowBackQuestionButton(true);
    if (!showNexQuestionButton) setShowNexQuestionButton(true);
    if (!showScoreBoard) setShowScoreBoard(true);
    if (showQLeaderBoard) setQShowLeaderBoard(false);
    if (showExplanation) setShowExplanation(false);
  };

  const onResultClick = () => {
    if (showQuestion) setShowQuestion(false);
    if (!showExplanationButton) setShowExplanationButton(true);
    if (showResultButton) setShowResultButton(false);
    if (!showBackQuestionButton) setShowBackQuestionButton(true);
    if (!showQLeaderBoard) setQShowLeaderBoard(true);
    if (showExplanation) setShowExplanation(false);
  };
  const onExplanationClick = () => {
    if (!showQuestion) setShowQuestion(true);
    if (showQLeaderBoard) setQShowLeaderBoard(false);
    if (showExplanationButton) setShowExplanationButton(false);
    if (showBackQuestionButton) setShowBackQuestionButton(false);
    if (!showExplanation) setShowExplanation(true);
    setCollapseQuestion(!collapseQuestion);
    const data = {
      team_name: team_name,
      question: questionData
    };
    socket.emit('on-explanation-click', data);
  };
  const onBackQuestionClick = () => {
    if (!showQuestion) setShowQuestion(true);
    if (!showExplanationButton) setShowExplanationButton(true);
    if (!showResultButton) setShowResultButton(true);
    if (showBackQuestionButton) setShowBackQuestionButton(false);
    if (showQLeaderBoard) setQShowLeaderBoard(false);
    if (!showScoreBoardButton) setShowScoreBoardButton(true);
    if (showScoreBoard) setShowScoreBoard(false);
    if (showExplanation) setShowExplanation(false);
    const data = {
      team_name: team_name,
      question: questionData
    };
    socket.emit('on-back-question', data);
  };

  const initSocketConnection = () => {
    console.log('initSocketConnection');
    joinTeam();
    setupSocketListeners();
    socket.on('welcome', (questionData) => {
      console.log(questionData, '===Welcome===');
    });
  };

  const onClientDisconnected = () => {
    console.log(
      'Connection Lost from server please check your connection.',
      'Error!'
    );
    setIsConnected(false);
  };

  const onReconnection = () => {
    setIsConnected(true);
    joinTeam();
  };

  const onQuestionSubmitRecieved = (data) => {
    console.log(data);
  };

  const setupSocketListeners = () => {
    if (!socket) return;
    socket.on('reconnect', onReconnection);
    socket.on('disconnect', onClientDisconnected);
    socket.on('on-question-submit', onQuestionSubmitRecieved);
  };

  return (
    <div className='presentation-container teacher-presentation-container'>
      <img src={logo} className='main-logo' />
      {startQuestions && (
        <div
          className='question-explanation'
          style={showExplanation ? { width: '100%' } : {}}
        >
          <div
            className={
              showExplanation
                ? 'question-container-with-exp'
                : 'question-container-without-exp'
            }
            style={collapseQuestion ? { display: 'none' } : { display: 'flex' }}
          >
            {showQuestion && questionData && (
              <Fragment>
                {/* <div className='question-number'>{`Question ${
            questionNumber + 1
          } of ${totalQuestions}`}</div> */}
                <QuestionView
                  question={questionData}
                  onTimerComplete={onTimerComplete}
                  showTimer={showTimer}
                />
              </Fragment>
            )}
            {showQLeaderBoard && questionScoreList && (
              <QuestionLeaderBoard questionScoreList={questionScoreList} />
            )}
            {showScoreBoard && quizUserAnswerList && (
              <UserLeaderBoard quizUserAnswerList={quizUserAnswerList} />
            )}

            <div className='button-container'>
              {showBackQuestionButton && (
                <input
                  className='theme-button blue'
                  type='button'
                  value='Back to Question'
                  onClick={onBackQuestionClick}
                />
              )}
              {showExplanationButton && (
                <input
                  className='theme-button blue'
                  type='button'
                  value='Explanation'
                  onClick={onExplanationClick}
                />
              )}
              {showResultButton && (
                <input
                  className='theme-button blue'
                  type='button'
                  value='Show Result'
                  onClick={onResultClick}
                />
              )}
              {showScoreBoardButton && (
                <input
                  className='theme-button blue'
                  type='button'
                  value='Score'
                  onClick={onScoreClick}
                />
              )}
              {totalQuestions != questionNumber + 1 &&
                showNexQuestionButton && (
                  <input
                    type='button'
                    className='theme-button blue'
                    value='Next Question'
                    onClick={OnNextQuestionClick}
                  />
                )}
            </div>
          </div>
          {showExplanation && questionData && (
            <QuestionExplanation
              onShowHideQuestionClick={onShowHideQuestionClick}
              question={questionData}
              showQuestionShowHide={true}
              userType='teacher'
            />
          )}
        </div>
      )}
      {!startQuestions && (
        <StartQuestion
          quizId={id}
          quiz={quiz}
          startQuestion={() => {
            setStartQuestions(!startQuestions);
          }}
          displayQuizDetails={() => {
            displayQuizDetails();
          }}
          moveToQuestion={moveToQuestion}
        />
      )}
      <div
        className='exit-presentation theme-button'
        onClick={() => {
          apiClient
            .stopQuiz(id)
            .then((res) => {
              history.push(`/quiz`);
            })
            .catch((err) => console.log(err));
        }}
      >
        Close
      </div>
    </div>
  );
};

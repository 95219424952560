import React, { FC, useState, useEffect } from 'react';
import './style.css';
import * as apiClient from '../../common/apiClient';
import { Standard, Subject } from '../types';
import { map, isEqual } from 'lodash';
import { QuestionSearchProps, ScholarSearchParam,  SearchParam } from './types';

const pleaseSelectSection: any = {
  id:0,
  class_id: 0,
  section: 'Please Select'
};
const pleaseSelectStandard: Standard = {
  class_id: 0,
  class_name: 'Please Select'
};
const initialSearch: ScholarSearchParam = {
  scholarno: '',
  name:'',
  classId : 0,
  sectionId:0,
};

export const ScholarSearch: FC<any> = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useState<ScholarSearchParam>(initialSearch);
  const [sectionList, setSectionList] = useState<any[]>([
    pleaseSelectSection
  ]);
  const [standardList, setStandardList] = useState<Standard[]>([
    pleaseSelectStandard
  ]);
  const getAllSections = () => {
    apiClient
      .getSectionsForClass(searchParams.classId)
      .then((res) => {
        if (res.status == 'success') {
          setSectionList([pleaseSelectSection, ...res.result]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStandards = () => {
    apiClient
      .getAllClasses()
      .then((res) => {
        if (res.status == 'success') {
          setStandardList([pleaseSelectStandard, ...res.result]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllStandards();
  }, []);
  const searchClick = () => {
    if (isEqual(initialSearch, searchParams)) return;
    onSearch(searchParams);
  };
  const resetClick = () => {
    setSearchParams(initialSearch);
  };
  return (
    <div className='search-container'>
      <div className='search-group'>
        <div className='text-label'>Standard</div>
        <div className='value-label'>
          <select
            value={searchParams.classId}
            name='standard'
            id='standard'
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                classId: Number(e.target.value)
              });
              getAllSections();
            }}
          >
            {map(standardList, (item) => {
              return (
                <option key={`standard${item.id}`} value={item.id}>
                  {item.class_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='text-label'>Section</div>
        <div className='value-label'>
          <select
            value={searchParams.sectionId}
            name='subject'
            id='subject'
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                sectionId: Number(e.target.value)
              });
            }}
          >
            {map(sectionList, (item) => {
              return (
                <option key={`section${item.id}`} value={item.id}>
                  {item.section}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='search-group-topic'>
        <div className='text-label'>Name</div>
        <div className='value-label'>
          <input
            id='chapter'
            name='chapter'
            type='text'
            value={searchParams.name}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                name: e.target.value
              });
            }}
          />
        </div>
        <div className='text-label'>Scholar No.</div>
        <div className='value-label'>
          <input
            id='topic'
            name='topic'
            type='text'
            value={searchParams.scholarno}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                scholarno: e.target.value
              });
            }}
          />
        </div>
      </div>
      <div className='search-btn'>
        <button type='button' onClick={searchClick}>
          Search
        </button>
        <div>
          <button
            style={{ marginLeft: '12px' }}
            type='button'
            onClick={resetClick}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

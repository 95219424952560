import React, { useState, useEffect } from 'react';
import { Edit, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required, 
    CheckboxGroupInput, RadioButtonGroupInput  } from 'react-admin';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";

export const TeacherClassCreatePopup = props => {
    const [sections, setSection] = useState<any>([]);
    const [subjects, setSubjects] = useState<any>([]);
    const [saving, setSaving] = useState(false);
    const defaultValues = { class_id: -1, };

    const getSectionsForClass = (classId) => {
        apiClient.getSectionsForClass(classId).then(res => {
            //console.log(res);
            if (res.status == "success") {
                setSection(res.result);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const getAllSubjects = () => {
        apiClient.getSubjects().then(res => {
            if (res.status == "success") {
                setSubjects(res.result);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    const handleSubmit = async (formdata) => {
        //console.log(formdata);
        //return;
        if (formdata.sections) {
            setSaving(true);
            for(let index = 0;index< formdata.subjects.length; index++){
                let data = {
                   employee_id: props.employee_id,
                    class_id: formdata.class_id,
                    section_id: formdata.sections,
                    subject_id: formdata.subjects[index],
                };
                await apiClient.addEntiyRecord("teacher_class_section", data);
            }
            props.onClose();
            setSaving(false);
        }

    }
    useEffect(() => {
        getAllSubjects();
    }, []);
    return (
        <Create title="Add Class" {...props}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                initialValues={defaultValues}
                variant="outlined">
                <ReferenceInput
                    source="class_id"
                    reference="genere"
                    label="Class"
                    fullWidth={true}
                    validate={[required()]}
                    onChange={(ev) => {
                        getSectionsForClass(ev.target.value);
                    }}
                    allowEmpty={true}
                    sort={{ field: 'class_name', order: 'ASC' }}
                >
                    <SelectInput allowEmpty={true} optionText="class_name" />
                </ReferenceInput>
                {sections.length > 0 && <RadioButtonGroupInput  fullWidth={true} source="sections" choices={sections} optionText="section" optionValue="id" />}
                {subjects.length > 0 && <CheckboxGroupInput  fullWidth={true} source="subjects" choices={subjects} optionText="subject" optionValue="id" />}
            </SimpleForm>
        </Create>
    );
}
import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const SectionList = props => {
    return (
        <List title="List of Sections" {...props}>
            <Datagrid rowClick="edit">
                {/* <TextField source="id" /> */}
                <ReferenceField source="class_id" reference="genere" link={false}>
                    <TextField source="class_name" />
                </ReferenceField>
                <TextField source="section" />
            </Datagrid>
        </List>
    );
}
import React, { useState, useEffect } from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation } from "react-router-dom";
import queryString from 'query-string';
import * as apiClient from "./apiClient";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useHistory
} from "react-router-dom";

const AppLoading = (props: any) => {

    let location = useLocation();
    let history = useHistory();
    const values = queryString.parse(location.search);
    let exitoken = (values.exitoken) ? values.exitoken : '';
    if (exitoken != "") {
        apiClient.loginUserByToken(exitoken).then(res => {
            if(res && res.result){
                let user = res;
                localStorage.setItem("firstname", user.firstname);
                localStorage.setItem("lastname", user.lastname);
                localStorage.setItem('username', user.email);
                localStorage.setItem("userId", user.userId);
                localStorage.setItem("type", user.userType);
                localStorage.setItem("accountid", user.accountid);
                localStorage.setItem("accounttype", user.accounttype);
                localStorage.setItem("rolename", user.rolename);
                history.push("/");
            }
        }).catch(err => {
            props.onExiTokenFailure();
        });
    }else{
        props.onExiTokenFailure();
    }
    return (
        <div className="full-loader-container">
            <CircularProgress color="secondary" />
        </div>
    );
}
export default AppLoading;


import React, { FC, useState, useEffect } from 'react';
import './style.css';
import * as apiClient from '../../common/apiClient';
import { Standard, Subject } from '../types';
import { map, isEqual } from 'lodash';
import { QuestionSearchProps, SearchParam } from './types';

const pleaseSelectSubject: Subject = {
  subject_id: 0,
  subject: 'Please Select'
};
const pleaseSelectStandard: Standard = {
  class_id: 0,
  class_name: 'Please Select'
};
const initialSearch: SearchParam = {
  chapter: '',
  subjectId: pleaseSelectSubject.subject_id,
  standardId: pleaseSelectStandard.class_id,
  topic: ''
};

export const QuestionSearch: FC<QuestionSearchProps> = ({ onSearch }) => {
  const [searchParams, setSearchParams] = useState<SearchParam>(initialSearch);
  const [subjectList, setSubjectList] = useState<Subject[]>([
    pleaseSelectSubject
  ]);
  const [standardList, setStandardList] = useState<Standard[]>([
    pleaseSelectStandard
  ]);
  const getAllSubjects = () => {
    apiClient
      .getSubjects()
      .then((res) => {
        if (res.status == 'success') {
          setSubjectList([pleaseSelectSubject, ...res.result]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllStandards = () => {
    apiClient
      .getAllClasses()
      .then((res) => {
        if (res.status == 'success') {
          setStandardList([pleaseSelectStandard, ...res.result]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllStandards();
    getAllSubjects();
  }, []);
  const searchClick = () => {
    if (isEqual(initialSearch, searchParams)) return;
    onSearch(searchParams);
  };
  const resetClick = () => {
    setSearchParams(initialSearch);
  };
  return (
    <div className='search-container'>
      <div className='search-group'>
        <div className='text-label'>Standard</div>
        <div className='value-label'>
          <select
            value={searchParams.standardId}
            name='standard'
            id='standard'
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                standardId: Number(e.target.value)
              });
            }}
          >
            {map(standardList, (item) => {
              return (
                <option key={`standard${item.id}`} value={item.id}>
                  {item.class_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='text-label'>Subject</div>
        <div className='value-label'>
          <select
            value={searchParams.subjectId}
            name='subject'
            id='subject'
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                subjectId: Number(e.target.value)
              });
            }}
          >
            {map(subjectList, (item) => {
              return (
                <option key={`subject${item.id}`} value={item.id}>
                  {item.subject}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className='search-group-topic'>
        <div className='text-label'>Chapter</div>
        <div className='value-label'>
          <input
            id='chapter'
            name='chapter'
            type='text'
            value={searchParams.chapter}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                chapter: e.target.value
              });
            }}
          />
        </div>
        <div className='text-label'>Topic</div>
        <div className='value-label'>
          <input
            id='topic'
            name='topic'
            type='text'
            value={searchParams.topic}
            onChange={(e) => {
              setSearchParams({
                ...searchParams,
                topic: e.target.value
              });
            }}
          />
        </div>
      </div>
      <div className='search-btn'>
        <button type='button' onClick={searchClick}>
          Search
        </button>
        <div>
          <button
            style={{ marginLeft: '12px' }}
            type='button'
            onClick={resetClick}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

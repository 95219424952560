import { DiscussionList } from './DiscussionList';
import { DiscussionEdit } from './DiscussionEdit';
import { DiscussionCreate } from './DiscussionCreate';

const role = localStorage.getItem('role');
const isStudent = role == 'student';
let discussion: any = {
  list: DiscussionList,
  create: DiscussionCreate,
  edit: DiscussionEdit
};
if (isStudent) {
  discussion = {
    list: DiscussionList
  };
}
export default discussion;

import React, { useState } from 'react';
import {  ScholarSearch } from './search';
import { useParams } from 'react-router-dom';
import { ScholarSearchParam, SearchParam } from './types';
import * as apiClient from '../../common/apiClient';
import { QuestionModel } from '../types';
import { ScholarList } from './scholar-list';
import './style.css';
import { find } from 'lodash';
import { useEffect } from 'react';
 
interface RouteParams {
  id: number;
}

export const QuizScholar = () => {
  const { id } = useParams<RouteParams>();
  const [scholarList, setScholarList] = useState<any[]>([]);
  const [selectedScholars, setSelectedScholars] = useState<any[]>(
    []
  );

  useEffect(() => {
    apiClient
      .getQuizScholars(id)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedScholars(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSearch = (searchParams: ScholarSearchParam) => {
    apiClient
      .getSearchScholar(
        searchParams.scholarno,
        searchParams.name,
        searchParams.classId == 0 ? '' : String(searchParams.classId),
        searchParams.sectionId == 0 ? '' : String(searchParams.sectionId)
      )
      .then((res) => {
        if (res.status == 'success') {
          setScholarList(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const add = (sid: number) => {
    console.log(sid, 'id');
    const questionAdd: any = find(scholarList, (item) => {
      return item.id == sid;
    });
    const questionIndex = selectedScholars.findIndex((item) => {
      return item.id == sid;
    });
    if (questionIndex !== -1) return;
    apiClient
      .addQuizScholar(id, sid, 1)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedScholars([...selectedScholars, questionAdd]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const remove = (sid: number) => {
    const questionIndex = selectedScholars.findIndex((item) => {
      return item.scholar_id == sid;
    });
    if (questionIndex === -1) return;
    const updatedSelectedQuestions = [...selectedScholars];
    const removedQuestion = updatedSelectedQuestions.splice(questionIndex, 1);
    apiClient
      .removeQuizScholar(id, removedQuestion[0].scholar_id)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedScholars(updatedSelectedQuestions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='quiz-question-container'>
      <div>{`Quiz:${id}`}</div>
      <ScholarSearch onSearch={onSearch} />
      <div style={{ display: 'flex' }}>
        <div className='question-result'>
          {scholarList && scholarList.length > 0 && (
            <>
              <span style={{ marginTop: '36px' }}>Search Result</span>
              <ScholarList
                scholars={scholarList}
                add={add}
              />
            </>
          )}
        </div>
        <div className='question-separator'></div>
        <div className='question-result'>
          {selectedScholars && selectedScholars.length > 0 && (
            <>
              <span style={{ marginTop: '36px' }}>Selected Students</span>
              <ScholarList
                scholars={selectedScholars}
                remove={remove}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default QuizScholar;

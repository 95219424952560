import React from 'react';
import { Create, SimpleForm, TextInput, SelectInput, NumberInput, BooleanInput, required, maxLength, minLength } from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeCreate = props => {
    const defaultValues = { is_active: true, faculty_type: 'Teacher' }
    return (
        <React.Fragment>
            <Create title="Edit Employee" {...props}>
                <SimpleForm warnWhenUnsavedChanges redirect="list" toolbar={<FormToolbar {...props} />}
                    variant="outlined" initialValues={defaultValues}>
                    <TextInput source="name" label="Employee Name" fullWidth={true} validate={[required()]} />
                    <NumberInput source="mobile_no" label="Mobile No" fullWidth={true} validate={[required(), maxLength(10), minLength(10)]} />
                    <SelectInput source="faculty_type" choices={[
                        { id: 'Principal', name: 'Principal' },
                        { id: 'Teacher', name: 'Teacher' },
                        { id: 'Office Executive', name: 'Office Executive' },
                    ]} label="Employee Type" fullWidth={true} />
                    <TextInput source="designation" label="Designation" fullWidth={true} validate={[required()]} />
                    <BooleanInput source="is_active" label="Is Active" fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Create>
        </React.Fragment>
    );
}
import React, { Fragment, useEffect, useState } from 'react';
import { DiscussionBoard } from '../discussion/board';
import { useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './style.css';
import * as apiClient from '../../common/apiClient';
import socketIOClient from 'socket.io-client';
import { ENDPOINT, PATH } from '../../env';
import { Drawer, makeStyles } from '@material-ui/core';
import { StudentList } from '../student/StudentList';
import { useRefresh } from 'react-admin';
import { toast } from 'react-toastify';
import { ProblemList } from '../problem/ProblemList';
import { ProblemCreate } from '../problem/ProblemCreate';
import { DiscussionProblem } from '../problem/types';

interface RouteParams {
  id: number;
}
const socket = socketIOClient(ENDPOINT, {
  path: PATH
});

const myStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 350
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 0,
    width: 650,
    maxWidth: '100%',
    paddingBottom: 50
  }
}));

export const FacultyDiscussion = (props) => {
  const refresh = useRefresh();
  const { id } = useParams<RouteParams>();
  const [collapseProblem, setCollapseProblem] = useState<boolean>(true);
  const [showStudents, setShowStudents] = useState<boolean>(false);
  const [showProblemUpload, setShowProblemUpload] = useState<boolean>(false);
  const [showProblems, setShowProblems] = useState<boolean>(false);
  const [allowedStudentId, setAllowedStudentId] = useState<number | undefined>(
    undefined
  );
  const [selectedProblem, setSelectedProblem] = useState<
    DiscussionProblem | undefined
  >(undefined);
  const setIsConnected = useState<boolean>(false)[1];
  const myclasses = myStyles({});

  const userId = localStorage.getItem('userid');
  const userIdInt = userId ? parseInt(userId) : 0;
  const team_name = `TEAM_DISCUSSION_${id}`;
  const isResolved = selectedProblem && selectedProblem.is_resolved == 1;
  const isPicked = selectedProblem && selectedProblem.is_picked == 1;

  useEffect(() => {
    //initSocketConnection();
  }, []);

  useEffect(() => {
    const data = {
      team_name: team_name,
      studentId: allowedStudentId
    };
    socket.emit('on-allow-revoke-student', data);
  }, [allowedStudentId]);

  const onShowHideProblem = () => {
    setCollapseProblem(!collapseProblem);
    const data = {
      team_name: team_name,
      problem: selectedProblem,
      collapseProblem: !collapseProblem
    };
    socket.emit('on-problem-show-hide', data);
  };

  const onViewStudents = () => {
    setShowStudents(true);
  };

  const onProblemUpload = () => {
    setShowProblemUpload(true);
  };

  const onViewProblems = () => {
    setShowProblems(true);
  };

  const onAllowStudent = (studentId: number) => {
    if (studentId == allowedStudentId) return;
    setAllowedStudentId(studentId);
    setShowStudents(false);
  };

  const onPickProblem = (problem: DiscussionProblem) => {
    apiClient
      .updateProblemPicked(problem.id, userIdInt, new Date())
      .then((res) => {
        if (res.status == 'success') {
          problem.is_picked = 1;
          setSelectedProblem(problem);
          setShowProblems(false);
          setCollapseProblem(false);
          toast.success('Problem picked successfully', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: 'dark'
          });
          const data = {
            team_name: team_name,
            problem: problem
          };
          socket.emit('on-problem-picked', data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onShowProblemDetail = (problem: DiscussionProblem) => {
    if (selectedProblem && problem.id == selectedProblem.id) return;
    setSelectedProblem(problem);
    setCollapseProblem(false);
    setShowProblems(false);
    const data = {
      team_name: team_name,
      problem: problem
    };
    socket.emit('on-show-problem-detail', data);
  };

  const onResolveProblem = (problem: DiscussionProblem) => {
    // TODO get boarddata from discussion board and pass it in boardData
    const boardData = 'boardData';
    apiClient
      .updateProblemResolved(problem.id, userIdInt, new Date(), boardData)
      .then((res) => {
        if (res.status == 'success') {
          setCollapseProblem(true);
          setSelectedProblem(undefined);
          toast.success('Problem resolved successfully', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: 'dark'
          });
          const data = {
            team_name: team_name
          };
          socket.emit('on-problem-resolved', data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onRevokeAccess = () => {
    if (allowedStudentId == 0) return;
    setAllowedStudentId(0);
    toast.success('Access revoked successfully', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'dark'
    });
  };

  const onStudentShowHideProblem = (data) => {
    setSelectedProblem(data.problem);
    setCollapseProblem(data.collapseProblem);
  };

  const onStudentShowProblemDetail = (data) => {
    setSelectedProblem(data.problem);
    setCollapseProblem(data.collapseProblem);
  };

  const joinTeam = () => {
    console.log(socket, '===join-team-discussion socket====');
    if (!socket) return;
    const data = {
      team_name: team_name,
      member: {
        user_id: userId
      }
    };
    socket.emit('join-team', data);
  };

  const setupSocketListeners = () => {
    if (!socket) return;
    socket.on('reconnect', onReconnection);
    socket.on('disconnect', onClientDisconnected);
    socket.on('on-student-show-hide-problem', onStudentShowHideProblem);
    socket.on('on-student-show-problem-detail', onStudentShowProblemDetail);
  };

  const initSocketConnection = () => {
    console.log('initSocketConnection');
    joinTeam();
    setupSocketListeners();
    socket.on('welcome', (questionData) => {
      console.log(questionData, '===Welcome Team Discussion===');
    });
  };

  const onClientDisconnected = () => {
    console.log(
      'Connection Lost from server please check your connection.',
      'Error!'
    );
    setIsConnected(false);
  };

  const onReconnection = () => {
    setIsConnected(true);
    joinTeam();
  };

  const onCancel = () => {
    if (showStudents) setShowStudents(false);
    if (showProblems) setShowProblems(false);
    if (showProblemUpload) setShowProblemUpload(false);
  };

  const closeProblemUploadSideSheet = () => {
    setShowProblemUpload(false);
  };

  const closeSideSheet = () => {
    onCancel();
    refresh();
  };
  console.log(showProblemUpload, 'showProblemUpload');
  return (
    <Fragment>
      <div className='faculty-container'>
        <img src={logo} className='main-logo' />
        <DiscussionBoard
          discussionProblem={selectedProblem}
          discussionId={id}
          canViewProblemDetail={collapseProblem}
          hasBoardPermission={true}
        />
        <div
          className='faculty-button-container'
          style={
            !collapseProblem && selectedProblem && (!isResolved || !isPicked)
              ? { justifyContent: 'space-between' }
              : { justifyContent: 'flex-end' }
          }
        >
          {!collapseProblem && selectedProblem && !isResolved && isPicked && (
            <input
              className='theme-button blue'
              type='button'
              value='Resolve'
              onClick={() => onResolveProblem(selectedProblem)}
            />
          )}
          {!collapseProblem && selectedProblem && !isResolved && !isPicked && (
            <input
              className='theme-button blue'
              type='button'
              value='Pick'
              onClick={() => onPickProblem(selectedProblem)}
            />
          )}
          <div className='board-button-container'>
            <input
              className='theme-button blue'
              type='button'
              value='View Problems'
              onClick={onViewProblems}
            />
            <input
              className='theme-button blue'
              type='button'
              value='View Students'
              onClick={onViewStudents}
            />
            <input
              className='theme-button blue'
              type='button'
              value='Upload Problem'
              onClick={onProblemUpload}
            />
            {selectedProblem && (
              <input
                type='button'
                className='theme-button blue'
                value='Show/Hide Problem'
                onClick={onShowHideProblem}
              />
            )}
            {allowedStudentId && (
              <input
                className='theme-button blue'
                type='button'
                value='Revoke Access'
                onClick={onRevokeAccess}
              />
            )}
          </div>
        </div>
      </div>
      <Drawer
        anchor='right'
        onClose={onCancel}
        classes={{
          paper: myclasses.drawerPaper
        }}
        open={showStudents || showProblems || showProblemUpload}
      >
        {showStudents && (
          <StudentList
            discussionId={id}
            {...props}
            onAllowAccess={(studentId) => {
              onAllowStudent(studentId);
            }}
            onClose={closeSideSheet}
            onCancel={onCancel}
            basePath='/scholar'
          />
        )}
        {showProblems && (
          <ProblemList
            {...props}
            discussionId={id}
            onPickProblem={(problem) => onPickProblem(problem)}
            onResolveProblem={(problem) => onResolveProblem(problem)}
            onShowProblemDetail={(problem) => onShowProblemDetail(problem)}
            onClose={closeSideSheet}
            onCancel={onCancel}
            basePath='/problem'
          />
        )}
        {showProblemUpload && (
          <ProblemCreate
            {...props}
            discussionId={id}
            onClose={closeProblemUploadSideSheet}
            onCancel={onCancel}
            basePath='/problem'
          />
        )}
      </Drawer>
    </Fragment>
  );
};

import React, { FC } from 'react';
import './style.css';
import { HorizontalBarChartProps } from './types';

const BarGroup = (props) => {
  let barPadding = 2;
  let barColour = '#348AA7';
  // let widthScale = (d) => d * 10;

  let width = props.d.width;
  let yMid = props.barHeight * 0.5;

  return (
    <g className='bar-group'>
      <text className='name-label' x='55' y={yMid} alignmentBaseline='middle'>
        {props.d.name}
      </text>
      <rect
        y={barPadding * 0.5}
        x={60}
        width={`${width}%`}
        height={props.barHeight - barPadding}
        fill={barColour}
      />
      <text
        className='value-label'
        // x={`${width + 1}%`}
        x={65}
        y={yMid}
        alignmentBaseline='middle'
      >
        {props.d.value}
      </text>
    </g>
  );
};

export const HorizontalBarChart: FC<HorizontalBarChartProps> = ({ data }) => {
  {
    let barHeight = 30;
    let barGroups = data.map((d, i) => (
      <g transform={`translate(0, ${i * barHeight})`} key={i}>
        <BarGroup d={d} barHeight={barHeight} />
      </g>
    ));

    return (
      <svg width='100%' height='55vh'>
        <g className='container'>
          <text className='title' x='50%' y='30' alignmentBaseline='middle'>
            Leaderboard
          </text>
          <g className='chart' transform='translate(55,60)'>
            {barGroups}
          </g>
        </g>
      </svg>
    );
  }
};

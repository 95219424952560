import React, { FC } from 'react';
import './style.css';
import { DiscussionBoardProps } from './types';
import { ProblemDetail } from '../../problem/ProblemDetail';
import * as apiClient from '../../../common/apiClient';
import { useHistory } from 'react-router-dom';
import { WhiteBoardUrl } from '../../../env';

export const DiscussionBoard: FC<DiscussionBoardProps> = ({
  discussionProblem,
  discussionId,
  canViewProblemDetail = false,
  hasBoardPermission = false
}) => {
  const role = localStorage.getItem('role');
  const isStudent = role == 'student';

  const history = useHistory();
  const boardUrl = `${WhiteBoardUrl}/discussion-board${discussionId}${
    discussionProblem?.id ? discussionProblem?.id : ''
  }${!hasBoardPermission ? '?hideMenu=true' : ''}`;
  return (
    <div className='discussion-main-container'>
      <div
        className='problem-container'
        style={canViewProblemDetail ? { width: '100%' } : {}}
      >
        <div
          className={
            canViewProblemDetail
              ? 'problem-container-with-board '
              : 'problem-container-without-board'
          }
          style={
            canViewProblemDetail ? { display: 'none' } : { display: 'flex' }
          }
        >
          {discussionProblem && <ProblemDetail problem={discussionProblem} />}
        </div>
        <div className='board-container'>
          <iframe
            frameBorder='0'
            className='drawing-tool'
            src={boardUrl}
          ></iframe>
        </div>
      </div>

      <div
        className='exit-presentation theme-button'
        onClick={() => {
          if(isStudent){
            history.push(`/discussion`);
            return;
          }
          apiClient
            .stopDiscussion(discussionId)
            .then((res) => {
              history.push(`/discussion`);
            })
            .catch((err) => console.log(err));
        }}
      >
        Close
      </div>
    </div>
  );
};

import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ImageInput,
  ImageField
} from 'react-admin';
import * as apiClient from '../../common/apiClient';
import CreateToolbar from '../../common/PopupCreateToolbar';
import { CustomLogoInputDisplay } from '../../CustomImage';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { lightTheme } from '../../layout/themes';

export const ProblemCreate = ({ discussionId, ...props }) => {
  const userIdStr = localStorage.getItem('userid');
  const userId = userIdStr ? parseInt(userIdStr) : 0;
  const handleSubmit = async (formdata) => {
    if (formdata.file_url != null && formdata.file_url.rawFile != null) {
      let uploadResult = await apiClient.innerUploadFile("file", formdata.file_url);
      formdata.file_url = uploadResult.result;
    }
    apiClient
      .addProblem(
        formdata.topic,
        formdata.file_url,
        userId,
        new Date(),
        discussionId
      )
      .then(() => {
        props.onClose();
      });
  };
  return (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
      <Create
        resource='problem'
        basePath='/problem'
        title='Discusison-Problem Upload'
        {...props}
      >
        <SimpleForm
          toolbar={
            <CreateToolbar
              onSave={handleSubmit}
              onClose={props.onClose}
              onCancel={props.onCancel}
            />
          }
          variant='outlined'
        >
          <TextInput
            source='topic'
            label='Topic'
            fullWidth={true}
            validate={[required()]}
          />
          <ImageInput source='file_url' label='Photo' accept='image/*'>
            <ImageField source='file_url' title='Photo' />
          </ImageInput>
          <CustomLogoInputDisplay />
        </SimpleForm>
      </Create>
    </ThemeProvider>
  );
};

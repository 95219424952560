import React,{useState} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as apiClient from "../../common/apiClient";
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import exportFromJSON from 'export-from-json'
const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 350,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
  export const Attendance = props => {
    
    const currentYear = new Date().getFullYear();
    const [cols, setCols] = useState([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [years, setYears] = useState([
        { id: '2023', name: '2023' },
        { id: '2024', name: '2024' },
        { id: '2025', name: '2025' },
        { id: '2026', name: '2026' },
        { id: '2027', name: '2027' },
        { id: '2028', name: '2028' },
        { id: '2029', name: '2029' },
        { id: '2030', name: '2030' }
    ]);
    const [year, setYear] = useState(currentYear);    

    const [months, setMonths] = useState([
        { id: '01', name: 'January' },
        { id: '02', name: 'February' },
        { id: '03', name: 'March' },
        { id: '04', name: 'April' },
        { id: '05', name: 'May' },
        { id: '06', name: 'June' },
        { id: '07', name: 'July' },
        { id: '08', name: 'August' },
        { id: '09', name: 'September' },
        { id: '10', name: 'October' },
        { id: '11', name: 'November' },
        { id: '12', name: 'December' }
    ]);
    const [month, setMonth] = useState(0);
    function exportToCSV(data) {
        const fileName = 'download'  
      const exportType = 'xls'
    exportFromJSON({ data, fileName, exportType })  
        
    }
    

    const generateReport = () => {
        const keyOrder = [
            "Institute Name",
            "MACHINE ID",
            "Applicant Name",
            "Father Name",
            "Application Id",
            "Exam",
            "RFID CARD NO",
            
          ];
        setFectched(false); 
        let data = {"MONTH_NO":month,"YEAR":year};
        setLoading(true);  
        apiClient.get_attendance(data).then(res => {
            console.log(res);
            if(res.length>0)
            {
                exportToCSV(res);
            }
            
            let responseData=res;
            // if (responseData.length > 0) {
            //      //responseData = renameKeys(responseData);     
            // }
            
            // const sortedData = data.map(item => {
            //     const sortedItem = {};
            //     keyOrder.forEach(key => {
            //       if (key in item) {
            //         sortedItem[key] = item[key];
            //       }
            //     });
            //     Object.keys(item).forEach(key => {
            //       if (!keyOrder.includes(key)) {
            //         sortedItem[key] = item[key];
            //       }
            //     });
            //     return sortedItem;
            //   });
            
            setData(responseData);
            setFectched(true);
            if (responseData.length > 0) {
                let keys = Object.keys(responseData[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    function renameKey ( obj, oldKey, newKey ) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      }
      const renameKeys = (originalArray) => {
        const keyOrder = Object.keys(originalArray[0]);
        
        return originalArray.map((item) => {
          const newItem = {};
          keyOrder.forEach((key) => {
            if (key.startsWith("day_")) {
              const originalKey = `day_${key.slice(4)}`;
              newItem[key] = item[originalKey];
            } else {
              newItem[key] = item[key];
            }
          });
          return newItem;
        });
      };
      

    const classes = useStyles();
    return (
        <div >
            <div className="report-heading">
                {"Attendnace"}
            </div>

            <div className="filters">
                
                <div style={{ textAlign: "left", marginLeft: "80px" }} className="filters-field" >
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Month</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Month" 
                        onChange={(e) => { setMonth(e.target.value); }} value={month} 
                        >
                            <MenuItem value={0}>{"-Select Month-"}</MenuItem>
                            {months && months.map((item) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    
                </div>
                

                <div style={{ textAlign: "left", marginLeft: "80px" }} className="filters-field" >
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Year</InputLabel>
                        <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="Month" 
                        onChange={(e) => { setYear(e.target.value); }} value={year} 
                        >
                            <MenuItem value={0}>{"-Select Year-"}</MenuItem>
                            {years && years.map((item) => {
                                return (<MenuItem value={item.id}>{item.name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    
                </div>
                






                
            </div>
            <></>
            
            {/* <div className="filter-action">
            {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
            </div> */}
            <div className="filter-action">
            
                <Button variant="contained"
                    onClick={() => {
                       generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Download Attendance
                </Button>
            </div>
            <div className="filter-action">
            {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                <div >
                    
                </div>
            </div>
            <div className="report-data-container" >
            <div className="report-data">

                {isLoading && <CircularProgress />}

                {/* <div className="w3-responsive">

                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item.toString().replace("day_","").replace("_"," ")}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div> */}
            </div>
            </div>
        </div>
    );
};
import React, { useState } from 'react';
import { QuestionSearch } from './search';
import { useParams } from 'react-router-dom';
import { SearchParam } from './types';
import * as apiClient from '../../common/apiClient';
import { QuestionModel } from '../types';
import { QuestionList } from './question-list';
import './style.css';
import { find } from 'lodash';
import { useEffect } from 'react';

interface RouteParams {
  id: number;
}

export const QuizQuestion = () => {
  const { id } = useParams<RouteParams>();
  const [questionList, setQuestionList] = useState<QuestionModel[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<QuestionModel[]>(
    []
  );

  useEffect(() => {
    apiClient
      .getQuizQuestion(id)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedQuestions(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSearch = (searchParams: SearchParam) => {
    apiClient
      .getSearchQuestion(
        searchParams.standardId == 0 ? '' : String(searchParams.standardId),
        searchParams.subjectId == 0 ? '' : String(searchParams.subjectId),
        searchParams.chapter,
        searchParams.topic
      )
      .then((res) => {
        if (res.status == 'success') {
          setQuestionList(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addQuestion = (questionId: number) => {
    console.log(questionId, 'questionId');
    const questionAdd: QuestionModel = find(questionList, (item) => {
      return item.question_id == questionId;
    });
    const questionIndex = selectedQuestions.findIndex((item) => {
      return item.question_id == questionId;
    });
    if (questionIndex !== -1) return;
    apiClient
      .addQuizQuestion(id, questionId, 1)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedQuestions([...selectedQuestions, questionAdd]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeQuestion = (questionId: number) => {
    const questionIndex = selectedQuestions.findIndex((item) => {
      return item.question_id == questionId;
    });
    if (questionIndex === -1) return;
    const updatedSelectedQuestions = [...selectedQuestions];
    const removedQuestion = updatedSelectedQuestions.splice(questionIndex, 1);
    apiClient
      .removeQuizQuestion(removedQuestion[0].id)
      .then((res) => {
        if (res.status == 'success') {
          setSelectedQuestions(updatedSelectedQuestions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='quiz-question-container'>
      <div>{`Quiz:${id}`}</div>
      <QuestionSearch onSearch={onSearch} />
      <div style={{ display: 'flex' }}>
        <div className='question-result'>
          {questionList && questionList.length > 0 && (
            <>
              <span style={{ marginTop: '36px' }}>Search Result</span>
              <QuestionList
                questions={questionList}
                addQuestion={addQuestion}
              />
            </>
          )}
        </div>
        <div className='question-separator'></div>
        <div className='question-result'>
          {selectedQuestions && selectedQuestions.length > 0 && (
            <>
              <span style={{ marginTop: '36px' }}>Selected Questions</span>
              <QuestionList
                questions={selectedQuestions}
                removeQuestion={removeQuestion}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default QuizQuestion;

import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import * as apiClient from '../../common/apiClient';

const StartButton = (props) => {
  const discussionId =
    props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const handleClick = () => {
    apiClient
      .startDiscussion(Number(discussionId), new Date())
      .then((res) => {
        if (res.status == 'success') {
          history.push(`/faculty-discussion/${discussionId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <input
      type='button'
      value='Start'
      className='theme-button blue size-medium'
      onClick={handleClick}
    />
  );
};

const JoinDiscussion = (props) => {
  const history = useHistory();
  const discussionId =
    props && props.record && props.record.id ? props.record.id : 0;
  const referenceIdStr = localStorage.getItem('reference_id');
  const referenceId = referenceIdStr ? parseInt(referenceIdStr) : 0;
  const handleClick = () => {
    apiClient
      .addStudentDiscussion(discussionId, referenceId, new Date())
      .then((res) => {
        if (res.status == 'success') {
          history.push(`/student-discussion/${discussionId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <input
      type='button'
      value='Join'
      className='theme-button blue size-medium'
      onClick={handleClick}
    />
  );
};

export const DiscussionList = (props) => {
  const role = localStorage.getItem('role');
  const isStudent = role == 'student';
  var filter;
  if (isStudent) {
    filter = { is_live: 1 };
  }
  return (
    <List
      title='List of Discussions'
      {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filter={filter}
    >
      <Datagrid rowClick={() => {}}>
        {!isStudent && <EditButton />}
        <TextField source='topic' />
        <ReferenceField source='class_id' reference='genere' link={false}>
          <TextField source='class_name' />
        </ReferenceField>
        <ReferenceField source='subject_id' reference='subject' link={false}>
          <TextField source='subject' />
        </ReferenceField>
        {!isStudent && <StartButton />}
        {isStudent && <JoinDiscussion />}
      </Datagrid>
    </List>
  );
};

import React, { useState, useEffect } from 'react';
import { Edit, Button, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required, Toolbar, SaveButton } from 'react-admin';
import { useForm } from 'react-final-form';
import * as apiClient from "../../common/apiClient";
import FileUpload from './FileUpload';
import { containerStyles } from "../../common/formStyles";
import CreateToolbar from '../../common/PopupCreateToolbarV1';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Attendance = props => {
    const currentYear = new Date().getFullYear();
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [csvData, setCsvData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [isLoading, setIsLoading] = useState(false);
    const classes = containerStyles({});
    const transformKeys = (obj) => {
        const transformedObj = {};
        for (const key in obj) {
            const newKey = key.toUpperCase().replace(/\s+/g, '_');
            transformedObj[newKey] = obj[key];
        }
        return transformedObj;
    };
    const transformFinalData = (originalData) => {
        return originalData.map(item => {
          return {
            APPLICANT_NAME: item.APPLICANT_NAME,
            FATHER_NAME: item.FATHER_NAME,
            APPLICATION_ID: item.APPLICATION_ID,
            EXAM: item.EXAM,
            RFID_CARD_NO: item.RFID_CARD_NO,
            ATTENDANCE: Object.keys(item).filter(key => key.match(/^\d+$/)).map(key => ({ ATTENDANCE_DATE: `${selectedYear}-${selectedMonth}-${parseInt(key)}`, ATTENDANCE_VALUE: item[key] }))
          };
        });
      };
    const handleFileUpload = (data) => {
        const transformedData = data.map(transformKeys);
        setCsvData(transformedData); 
    };
    const handleSubmit = async (formdata) => {
        setIsLoading(true);
        setMessage("Please wait.....")
        setErrorMessage('');
        const transformedData = transformFinalData(csvData); 
        const payload={
            MONTH_NO:selectedMonth,
            YEAR:selectedYear,
            DATA:transformedData,
        }
        apiClient.UploadAttendance(payload).then((res) => {
            setMessage(res.message)
            setIsLoading(false);
        }) .catch((err) => {
            setMessage("")
            setErrorMessage("Please check file format or validate data")
            setIsLoading(false);
          });
        ;
        
      };
    return (
        <React.Fragment>
            <Create title="Change Password" {...props} basePath="changepassword" resource="changepassword">
                <SimpleForm
                    // toolbar={csvData.length > 0 ? <ChangePasswordCreateToolbar   setMessage={setMessage} /> : null}
                    toolbar={isLoading==false&& 
                        <CreateToolbar
                         label="Upload Attendance"
                          onSave={handleSubmit}
                          onClose={props.onClose}
                          onCancel={props.onCancel}
                        />
                      }
                    variant="outlined">
                    <h3>Upload Attendance</h3>
                    <SelectInput source="month_no" validate={[required()]} label="Month" fullWidth={true} formClassName={classes.first_inline_input}
                        onChange={(event) => {
                            setSelectedMonth(event.target.value)
                        }
                        }
                        choices={[
                            { id: '01', name: 'January' },
                            { id: '02', name: 'February' },
                            { id: '03', name: 'March' },
                            { id: '04', name: 'April' },
                            { id: '05', name: 'May' },
                            { id: '06', name: 'June' },
                            { id: '07', name: 'July' },
                            { id: '08', name: 'August' },
                            { id: '09', name: 'September' },
                            { id: '10', name: 'October' },
                            { id: '11', name: 'November' },
                            { id: '12', name: 'December' }
                        ]} />

                    <SelectInput source="year" label="Year" fullWidth={true} validate={[required()]} formClassName={classes.last_inline_input}
                        initialValue={currentYear}
                        onChange={(event) => {
                            setSelectedYear(event.target.value)
                        }
                        } 
                        choices={[
                            { id: '2023', name: '2023' },
                            { id: '2024', name: '2024' },
                            { id: '2025', name: '2025' },
                            { id: '2026', name: '2026' },
                            { id: '2027', name: '2027' },
                            { id: '2028', name: '2028' },
                            { id: '2029', name: '2029' },
                            { id: '2030', name: '2030' }
                        ]} />

                     <FileUpload onFileUpload={handleFileUpload} />
                     
                </SimpleForm>
                
                
            </Create>
            {message && <div className="change-password-result-success">{message}</div>}
            {errorMessage && <div className="change-password-result-error">{errorMessage}</div>}
        
        </React.Fragment>
    );


}
import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required
} from 'react-admin';
import { FormToolbar } from '../../common/FormToolbar';

export const DiscussionEdit = (props) => {
  return (
    <Edit undoable={false} title='Section Edit' {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant='outlined'>
        <TextInput
          source='topic'
          label='Topic'
          fullWidth={true}
          validate={[required()]}
        />
        <ReferenceInput source='class_id' reference='genere' fullWidth={true}>
          <SelectInput optionText='class_name' />
        </ReferenceInput>
        <ReferenceInput
          source='subject_id'
          reference='subject'
          fullWidth={true}
        >
          <SelectInput optionText='subject' />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

import React from 'react';
import { Edit, SimpleForm, TextInput,FormDataConsumer, DateInput, ReferenceInput, SelectInput, BooleanInput, required } from 'react-admin';
import { containerStyles } from "../../common/formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import {ClassSectionFields} from "./ClassSectionFields";

export const ScholarEdit = props => {
    const classes = containerStyles({});
    return (
        <Edit title="Scholar Edit" {...props}>
            <SimpleForm toolbar={<FormToolbar {...props} />}
                variant="outlined">
                {/* <TextInput source="id"  /> */}
                <TextInput source="scholar_no" label="Scholar No." fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="session" label="Academic Session" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="name" label="Name" fullWidth={true} formClassName={classes.first_inline_input} />
                <DateInput source="dob" label="Date of birth" fullWidth={true} formClassName={classes.last_inline_input} />
                <SelectInput source="gender" label="Gender" fullWidth={true} formClassName={classes.first_inline_input}
                    choices={[
                        { id: 'Male', name: 'Male' },
                        { id: 'Female', name: 'Female' }
                    ]} />
                <TextInput source="father" label="School" fullWidth={true} formClassName={classes.last_inline_input} />
                <TextInput source="mother" label="City" fullWidth={true} formClassName={classes.first_inline_input} />
                <TextInput source="alert_mobile_no" label="Alert Mobile No" fullWidth={true} formClassName={classes.last_inline_input} />
                <FormDataConsumer>
                        {formDataProps => (
                            <ClassSectionFields {...formDataProps} />
                        )}
                    </FormDataConsumer>
                {/* <ReferenceInput source="class_id" reference="genere" fullWidth={true} formClassName={classes.first_inline_input}>
                    <SelectInput optionText="class_name" fullWidth={true} />
                </ReferenceInput>
                <ReferenceInput source="section_id" reference="section" fullWidth={true} formClassName={classes.last_inline_input}>
                    <SelectInput optionText="section" />
                </ReferenceInput> */}
                <BooleanInput source='is_active' label="Is Active" initialValue={true} />
            </SimpleForm>
        </Edit>
    );
}
import React, { FC, useEffect, useState } from 'react';
import { HorizontalBarChart } from '../horizontal-bar-chart';
import { UserScoreBoardProps } from '../user-score-board/types';
import { HorizontalBarChartData } from '../horizontal-bar-chart/types';
import { max, map } from 'lodash';
import { UserScore } from '../../types';
import "./style.css";

export const UserLeaderBoard: FC<UserScoreBoardProps> = ({
  quizUserAnswerList
}) => {
  const chartData: HorizontalBarChartData[] = [];
  const maxWidth: number = 80;
  const maxScore = max(
    map(quizUserAnswerList, (item: UserScore) => {
      return Number(item.score);
    })
  );

  for (let i = 0; i < quizUserAnswerList.length; i++) {
    chartData.push({
      name: quizUserAnswerList[i].username,
      city: quizUserAnswerList[i].city,
      value: Number(quizUserAnswerList[i].score),
      width:
        maxScore == Number(quizUserAnswerList[i].score)
          ? maxWidth
          : Math.round(
            (maxWidth / maxScore) * Number(quizUserAnswerList[i].score)
          )
    });
  }
  return (
    <div className='user-leaderboard-data'>
      <div className="leaderboard-parent">
        {chartData && chartData.map((item, index) => {
          return (
            <div className="item-parent" data-label="In Progress" key={index}>
              <div className="position badge-num">{index + 1}</div>
              <div className="name">
                <div className="student-name">
                  {item.name}
                </div>
                <div className="city-name">
                  {item.city}
                </div>
              </div>
              <div className="score score-num">{item.value}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

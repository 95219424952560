import React from 'react';
import { Edit, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";

export const SectionCreate = props => {
    return (
        <Create title="Section Edit" {...props}>
            <SimpleForm redirect="list" toolbar={<FormToolbar {...props} />}
                variant="outlined">
                {/* <TextInput source="id" /> */}
                <ReferenceInput source="class_id" reference="genere" fullWidth={true}>
                    <SelectInput optionText="class_name" />
                </ReferenceInput>
                <TextInput source="section" fullWidth={true} validate={[required()]} />
            </SimpleForm>
        </Create>
    );
}
import React, { useState, useCallback, useEffect } from 'react';
import {
    useRefresh,
    Edit, TabbedForm, FormTab, TextInput, ReferenceInput,
    SelectInput, ReferenceManyField, required, Datagrid, TextField
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles, Drawer } from '@material-ui/core';
import CDeleteButton from "../../common/CDeleteButton";
import { SectionCreatePopup } from "./SectionCreatePopup";
import { FormToolbar } from "../../common/FormToolbar";

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 350,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 0,
        width: 650,
        maxWidth: "100%",
        paddingBottom: 50
    },
}));

export const ClassEdit = props => {
    const myclasses = myStyles({});
    const refresh = useRefresh();
    //STATE
    const [isaddSecton, setIsAddSection] = useState(false);

    // PRIVATE METHODS
    const closeSectionForm = () => {
        setIsAddSection(false);
        refresh();
    }
    const onCancel = () => {
        setIsAddSection(false);
    }
    return (
        <React.Fragment>
            <Edit title="Edit Class" {...props}>
                <TabbedForm variant="outlined" toolbar={<FormToolbar {...props} />}>
                    <FormTab {...props} label="Class Details">
                        {/* <TextInput source="id" fullWidth={true} /> */}
                        <ReferenceInput source="class_group_id" reference="classgroup" fullWidth={true} link={false} sort={{ field: 'id', order: 'ASC' }}>
                            <SelectInput optionText="class_group" />
                        </ReferenceInput>
                        <TextInput source="class_name" label="Class" fullWidth={true} validate={[required()]} />
                        <TextInput source="numeric_equivalent" label="Numeric Equivalent" fullWidth={true} validate={[required()]} />
                    </FormTab>
                    <FormTab {...props} label="Class Sections">
                        <Button variant="contained" onClick={() => { setIsAddSection(true); }}
                            color="secondary">
                            Add Section
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="section"
                            target="class_id">
                            <Datagrid>
                                <CDeleteButton {...props} />
                                <TextField fullWidth={true} source="section" label="Section" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={isaddSecton}>
                {isaddSecton && <SectionCreatePopup
                    onClose={closeSectionForm}
                    onCancel={onCancel}
                    {...props}
                    basePath="/section"
                    class_id={props.id} />}
            </Drawer>
        </React.Fragment>
    );
}
import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  NumberInput,
  required
} from 'react-admin';
import { FormToolbar } from '../../common/FormToolbar';
import {CustomLogoInputDisplay} from '../../CustomImage';
import { containerStyles } from "../../common/formStyles";

export const QuestionEdit = (props) => {
  const classes = containerStyles({});
  return (
    <Edit undoable={false} title='Section Edit' {...props}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant='outlined'>
        <ImageInput source="question_img_url" label="Question Image" accept="image/*">
          <ImageField source="question_img_url" title="Question Image" />
        </ImageInput>
        <CustomLogoInputDisplay />

        <NumberInput
        formClassName={classes.first_inline_input}
          source='duration'
          label='Duration'
          fullWidth={true}
          validate={[required()]}
        />
        <NumberInput
        formClassName={classes.last_inline_input}
          source='option_count'
          label='Option Count'
          fullWidth={true}
          validate={[required()]}
        />
        <ReferenceInput source='class_id' 
        formClassName={classes.first_inline_input}
        reference='genere' fullWidth={true}>
          <SelectInput optionText='class_name' />
        </ReferenceInput>
        <ReferenceInput
        formClassName={classes.last_inline_input}
          source='subject_id'
          reference='subject'
          fullWidth={true}
        >
          <SelectInput optionText='subject' />
        </ReferenceInput>

        <TextInput
        formClassName={classes.first_inline_input}
          source='chapter_name'
          label='Chapter Name'
          fullWidth={true}
          validate={[required()]}
        />

        <TextInput
        formClassName={classes.last_inline_input}
          source='topic'
          label='Topic'
          fullWidth={true}
        />
        <TextInput
          source='correct_option'
          label='Correct Option'
          fullWidth={true}
        />
      </SimpleForm>
    </Edit>
  );
};

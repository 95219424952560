import React, { FC } from 'react';
import { ProblemDetailProps } from './types';

const ProblemItem = ({ label, value }) => {
  return (
    <div>
      <div className='text-label'>{label}</div>
      <div className='value-label'>{value}</div>
    </div>
  );
};

export const ProblemDetail: FC<ProblemDetailProps> = ({ problem }) => {
  return (
    <div className='problem-detail-container'>
      <h2>Problem Detail</h2>
      <ProblemItem label='Topic' value={problem.topic} />
      <div className='text-label'>Photo</div>
      <img className='problem-img' src={problem.file_url} />
    </div>
  );
};

import React, { FC } from 'react';
import { QuestionScore } from '../../types';
import { QuestionLeaderBoardProps } from './types';
import { VerticalBarChart } from '../vertical-bar-chart';
import { filter } from 'lodash';
import { VerticalBarChartData } from '../vertical-bar-chart/types';

export const QuestionLeaderBoard: FC<QuestionLeaderBoardProps> = ({
  questionScoreList
}) => {
  const chartData: VerticalBarChartData[] = [];
  const option_count =
    questionScoreList.length > 0
      ? Number(questionScoreList[0].option_count)
      : 0;
  const correct_option =
    questionScoreList.length > 0
      ? Number(questionScoreList[0].correct_option)
      : 0;

  const options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

  for (let i = 0; i < option_count; i++) {
    const countOption = filter(questionScoreList, (item: QuestionScore) => {
      return Number(item.option_answer) === i + 1;
    }).length;
    chartData.push({
      optionText: options[i],
      value: countOption,
      isCorrect: correct_option === i + 1
    });
  }
  return (
    <div className='leaderboard-bar-container'>
      <VerticalBarChart data={chartData} />
    </div>
  );
};

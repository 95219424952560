import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const ClassSectionFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div className="address-field-parent">
            <ReferenceInput variant="outlined" label="Class" source="class_id" reference="genere"
                onChange={(v) => {
                    console.log(v);
                    //form.change("stateid", 0);
                    form.change("section_id", 0);
                }}
                validate={required()}
                sort={{ field: 'numeric_equivalent', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.first_inline_input}>
                <SelectInput optionText="class_name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Section" source="section_id"
                reference="section" perPage={500}
                filter={{ "class_id=": formData.class_id }}
                validate={required()}
                sort={{ field: 'section', order: 'ASC' }}
                fullWidth={true} className={classes.last_inline_input}>
                <SelectInput validate={required()} optionText="section" />
            </ReferenceInput>
        </div>
    )
}

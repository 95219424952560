import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//#1976d2" : Royal blue
// 84254A
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor:"#4A6855",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);



const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function UpcomingAssignmentList({ data }) {
    const classes = useStyles();
    return (
        <Table className={classes.table} size="small" aria-label="customized table">
            <TableHead>
                <TableRow>
                    <StyledTableCell>Quiz</StyledTableCell>
                    <StyledTableCell>Subject</StyledTableCell>
                    <StyledTableCell>Topic</StyledTableCell>
                    <StyledTableCell>Class</StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                            {row.content_type_id}
                        </StyledTableCell>
                        <StyledTableCell>{row.subject}</StyledTableCell>
                        <StyledTableCell >{row.topic}</StyledTableCell>
                        <StyledTableCell >{row.submission_date}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}

import React from 'react';
import { Edit, SimpleForm, TextInput, required } from 'react-admin';
import {FormToolbar} from "../../common/FormToolbar";

export const SubjectEdit = props => {
    return (
        <React.Fragment>
            <Edit label="Edit Subject" {...props}>
                <SimpleForm   toolbar={<FormToolbar {...props} />}
                variant="outlined">
                    {/* <TextInput source="id" /> */}
                    <TextInput source="subject" label='Subject' fullWidth={true} validate={[required()]} />
                </SimpleForm>
            </Edit>
        </React.Fragment>
    );
}
import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

export const SubjectList = props => {
    return (
        <React.Fragment>
            <List title="List of Subjects" {...props} sort={{ field: 'subject', order: 'ASC' }}>
                <Datagrid rowClick="edit" >
                    {/* <TextField source="id" /> */}
                    <TextField source="subject" label="Subject" />
                </Datagrid>
            </List>
        </React.Fragment>
    )
}
import React from 'react';
import { ImageField } from 'react-admin';
import { ImageInput } from 'react-admin';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required
} from 'react-admin';
import { FormToolbar } from '../../common/FormToolbar';
import { containerStyles } from "../../common/formStyles";

const CustomImageInputDisplay = (props) => {
  return (
      <span>
          <div>Existing Image</div>
          {props.record.quiz_detail_image && <img className="uploaded-image" src={props.record.quiz_detail_image}/>}
      </span>
  )
};

export const QuizEdit = (props) => {
  const classes = containerStyles({});
  return (
    <Edit  undoable={false} title='Section Edit' {...props}>
      <SimpleForm  toolbar={<FormToolbar {...props} />} variant='outlined'>
      <TextInput
          source='name'
          label='Quiz Name'
          fullWidth={true}
          formClassName={classes.first_inline_input}
          validate={[required()]}
        />
        <TextInput
          source='description'
          label='Description'
          fullWidth={true}
          formClassName={classes.last_inline_input}
        />
        <ReferenceInput source='class_id' reference='genere' fullWidth={true} formClassName={classes.first_inline_input}>
          <SelectInput optionText='class_name' />
        </ReferenceInput>
        <ReferenceInput
          source='subject_id'
          reference='subject'
          fullWidth={true}
          formClassName={classes.last_inline_input}
        >
          <SelectInput optionText='subject' />
        </ReferenceInput>
        <SelectInput source="marking" choices={[
          { id: 'Regular', name: 'Regular' },
          { id: 'Negative', name: 'Negative' },
        ]} 
        fullWidth={true}
        formClassName={classes.first_inline_input}
        />
        <TextInput
          source='youtube_video_id'
          label='You tube Video Id'
          fullWidth={true}
          formClassName={classes.last_inline_input}
        />
        
        <ImageInput source="quiz_detail_image" label="Quiz Details Image" accept="image/*">
          <ImageField source="quiz_detail_image" title="Quiz Details Image" />
        </ImageInput>
        <CustomImageInputDisplay />

      </SimpleForm>
    </Edit>
  );
};

import React from 'react';
import { ImageField } from 'react-admin';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { lightTheme } from '../../layout/themes';
import './style.css';
import { FunctionField } from 'react-admin';

const MyFilters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='Topic' source='topic' alwaysOn />
  </Filter>
);

const PickProblem = ({ onPickProblem, ...props }) => {
  const problem = props && props.record;

  return (
    <input
      type='button'
      value='Pick'
      className='theme-button blue size-medium'
      onClick={() => onPickProblem(problem)}
    />
  );
};

const ResolveProblem = ({ onResolveProblem, ...props }) => {
  const problem = props && props.record;

  return (
    <input
      type='button'
      value='Resolve'
      className='theme-button blue size-medium'
      onClick={() => onResolveProblem(problem)}
    />
  );
};

export const ProblemList = ({
  discussionId,
  onPickProblem,
  onResolveProblem,
  onShowProblemDetail,
  ...props
}) => {
  const roleName = localStorage.getItem('role');
  const isStudent = roleName == 'student';
  return (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
      <div className='problem-list-container'>
        <List
          actions={<></>}
          exporter={false}
          bulkActionButtons={false}
          filter={{ discussion_id: discussionId }}
          resource='problem'
          basePath='/problem'
          title='List of Problems'
          sort={{ field: 'update_date', order: 'desc' }}
          {...props}
          filters={<MyFilters />}
        >
          <Datagrid
            rowClick={(id, basePath, record) => onShowProblemDetail(record)}
          >
            <TextField source='topic' label='Topic' />
            <ImageField source='file_url' label='Photo' />
            <FunctionField
              label='Status'
              render={(record) =>
                `${
                  record.is_resolved == 1
                    ? 'Resolved'
                    : record.is_picked == 1
                    ? 'Picked'
                    : 'Uploaded'
                } `
              }
            />
            {!isStudent && (
              <FunctionField
                render={(record) => {
                  const isPicked = parseInt(record.is_picked) === 1;
                  const isResolved = parseInt(record.is_resolved) === 1;
                  if (!isPicked && !isResolved)
                    return (
                      <PickProblem
                        onPickProblem={() => onPickProblem(record)}
                      />
                    );
                  if (isPicked && !isResolved)
                    return (
                      <ResolveProblem
                        onResolveProblem={() => onResolveProblem(record)}
                      />
                    );
                }}
              />
            )}
          </Datagrid>
        </List>
      </div>
    </ThemeProvider>
  );
};

import * as http from "./http";
import { APIUrl } from "../env";
export const isDemoSite = false;

export const innerUploadFile = (field, data) => {
  const formData = new FormData();
  formData.append(field, data.rawFile);
  return fetch(`${APIUrl}upload`, {
    method: "POST",
    body: formData
  })
    .then((response) => response.json())
    .then((responseJson) => {
      return responseJson;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getVideoId = (content_url) => {
  var aData = content_url.split("/");
  var index = aData.length - 1;
  return aData[index];
};
export const VideoPatch = (url, token, model) => {
  let headers = new Headers();
  headers.append("Authorization", "Bearer " + token);
  headers.append("Content-Type", "application/json");
  return fetch(url, {
    method: "PATCH",
    body: JSON.stringify(model),
    headers
  }).then((r) => {
    if (r.status == 409) {
      return r.json();
    }
    if (!r.ok) {
      throw Error(r.statusText);
    }
    return r.json();
  });
};

export const updateVideoMeta = (video_id, token, title, description) => {
  let api = "https://api.vimeo.com/videos/" + video_id;
  let data = {
    name: title,
    description: description
  };
  return VideoPatch(api, token, data).then((response) => {
    return response;
  });
};
export const registerUser = (user) => {
  let api = APIUrl + "registerUser";
  return http.post(api, user).then((response) => {
    return response;
  });
};
export const approveContent = (contentId, approvalFlag) => {
  let api = APIUrl + "content/approve_disapprove_content";
  let data = {
    id: contentId,
    status: approvalFlag
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const loginUser = (user) => {
  let api = APIUrl + "login/login";
  return http.post(api, user).then((response) => {
    return response;
  });
};

export const StudentTracking = (content_id) => {
  let api = APIUrl + "studenttracking";
  let data = {
    content_id: content_id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const ContentReadStatus = (content_id) => {
  let api = APIUrl + "contentreadstatus";
  let data = {
    content_id: content_id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getOrgData = () => {
  let api = APIUrl + "org/1";
  return http.get(api).then((response) => {
    return response;
  });
};

export const getStudentAssignment = () => {
  let api = APIUrl + "dashboard/upcoming_assignments";
  return http.get(api).then((response) => {
    return response;
  });
};

export const loginUserByToken = (token) => {
  let api = APIUrl + "userById";
  let user = {
    token: token,
    StartIndex: 0,
    PageSize: 10
  };
  return http.post(api, user).then((response) => {
    return response;
  });
};

export const deleteRecord = (path, id) => {
  let api = APIUrl + path + "/" + id;
  return http.del(api).then((response) => {
    return response;
  });
};

export const addEntiyRecord = (resource, data) => {
  let api = APIUrl + resource;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const ChangePassword = (data) => {
  let api = APIUrl + "user/update_password";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const UploadAttendance = (data) => {
  let api = APIUrl + "attendance/upload";
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const reviewAssignment = (data) => {
  let api = APIUrl + "assignment/review_assignment";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const test_assignment_check = (data) => {
  let api = APIUrl + "tl_assignment_check";
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getSubjectsForStudent = () => {
  let api = APIUrl + "subject/get_subjects_for_logged_user";
  return http.get(api).then((response) => {
    return response;
  });
};

export const getContentPageForSubject = (subjectId) => {
  let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getAccountToken = (accountId) => {
  let api = APIUrl + `account/get_account_token`;
  let data = { accountId: accountId };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const getSectionsForClass = (classId) => {
  //let api = APIUrl + `section/${classId}`;
  var filter = { "class_id=": classId };
  const filterString = JSON.stringify(filter);
  let api = `${APIUrl}/section?filter=${filterString}`;
  return http.get(api).then((response) => {
    return response;
  });
};


export const getSubjects = () => {
  let api = `${APIUrl}/subject?pagesize=500`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getMeetingSchedule = (id) => {
  let api = APIUrl + `meeting_schedule/${id}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const startMeeting = (id) => {
  let api = APIUrl + `meeting_schedule/flag_meeting_as_started`;
  let data = {
    id: id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const stopMeeting = (id) => {
  let api = APIUrl + `meeting_schedule/flag_meeting_as_stopped`;
  let data = {
    id: id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};
//Dashboard APIs
export const getAllClasses = () => {
  let api =
    APIUrl +
    `genere?pagesize=30&page=1&sortby=numeric_equivalent&sortorder=ASC&filter={}`;
  return http.get(api).then((response) => {
    return response;
  });
};
export const get_scholars_for_assignment = (
  class_id,
  assignmentCode,
  submitStatus
) => {
  let api = APIUrl + `scholar/get_scholars_for_assignment`;
  let data = {
    class_id: class_id,
    assignment_id: assignmentCode,
    submit_status: submitStatus
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const get_all_users = () => {
  let api = APIUrl + `user/get_all_users`;
  return http.get(api).then((response) => {
    return response;
  });
};
export const get_all_assignments = () => {
  let api = APIUrl + `content/get_all_assignments`;
  let data = {};
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const get_all_classes_for_employee = () => {
  let api = APIUrl + `genere/get_all_classes_for_employee`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const get_all_assignments_for_class_from_faculty = (class_id) => {
  let api = APIUrl + `content/get_all_assignments_for_class_from_faculty`;
  let data = {
    class_id: class_id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const student_tracking_track_student = (scholar_no) => {
  let api = APIUrl + `student_tracking/track_student`;
  let data = {
    scholar_no: scholar_no
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const get_all_quizes = () => {
  let api = APIUrl + `quiz?pagesize=100`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const quiz_details_report = (quiz_id) => {
  let api = APIUrl + `quiz/get_quiz_report?quiz_id=${quiz_id}`;
  
  return http.get(api).then((response) => {
    return response;
  });
};

export const quiz_student_details_report = (quiz_id, user_id) => {
  let api = APIUrl + `quiz/get_quiz_student_report?quiz_id=${quiz_id}&user_id=${user_id}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getSearchQuestion = (
  classId: string,
  subjectId: string,
  chapter: string,
  topic: string
) => {
  let filter = {};
  if (classId) filter["class_id="] = classId;
  if (subjectId) filter["subject_Id="] = subjectId;
  if (chapter) filter["chapter_name"] = chapter;
  if (topic) filter["topic"] = topic;
  const filterString = JSON.stringify(filter);
  let api = `${APIUrl}/question/?pagesize=30&page=1&sortby=id&sortorder=ASC&filter=${filterString}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getSearchScholar = (
  scholarno: string,
  name: string,
  classId: string,
  sectionId: string,
) => {
  let filter = {};
  if (classId && classId != "") filter["class_id="] = classId;
  if (sectionId && sectionId != "") filter["sectionId="] = sectionId;
  if (scholarno) filter["scholarno"] = scholarno;
  if (name) filter["name"] = name;

  const filterString = JSON.stringify(filter);
  let api = `${APIUrl}/scholar/?pagesize=30&page=1&sortby=id&sortorder=ASC&filter=${filterString}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getQuizQuestion = (quizId: number) => {
  let api = `${APIUrl}/quiz/get_questions_for_quiz/?quiz_id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getQuizScholar = (quizId: number) => {
  let api = `${APIUrl}/quiz/get_scholars_for_quiz/?quiz_id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getQuizScholars = (quizId: number) => {
  let api = `${APIUrl}/quiz/get_scholars_for_quiz/?quiz_id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};


export const getQuiz = (quizId: number) => {
  let api = `${APIUrl}/quiz/?id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const updateQuestionScore = (
  quizId: number,
  question_id: number | undefined
) => {
  let api = `${APIUrl}/quiz/update_question_score?quiz_id=${quizId}&question_id=${question_id}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getQuizUserLeaderBoard = (quizId: number) => {
  let api = `${APIUrl}/quiz/get_user_leaderboard_for_quiz/?quiz_id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const getQuizQuestionLeaderBoard = (questionId: number, quizId: number) => {
  let api = `${APIUrl}/quiz/get_question_leaderboard/?question_id=${questionId}&quiz_id=${quizId}`;
  return http.get(api).then((response) => {
    return response;
  });
};

export const startQuiz = (quiz_id: number, start_date: Date) => {
  const data = {
    quiz_id: quiz_id,
    start_date: start_date
  };
  let api = APIUrl + `quiz/update_quiz_start`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const stopQuiz = (quiz_id: number) => {
  const data = {
    quiz_id: quiz_id
  };
  let api = APIUrl + `quiz/update_quiz_stop`;
  return http.post(api, data).then((response) => {
    return response;
  });
};


export const updateCurrentQuestion = (quiz_id: number, question_id: number) => {
  const data = {
    quiz_id: quiz_id,
    question_id: question_id
  };
  let api = APIUrl + `quiz/update_quiz_current_question`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const addQuizQuestion = (
  quiz_id: number,
  question_id: number,
  question_order?: number
) => {
  if (!question_order) question_order = 1;
  const data = {
    quiz_id: quiz_id,
    question_id: question_id,
    question_order: question_order
  };
  let api = APIUrl + `quiz_question`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const removeQuizQuestion = (id: number) => {
  let api = APIUrl + `quiz_question/quiz_question_delete`;
  const data = {
    id: id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};


export const addQuizScholar = (
  quiz_id: number,
  scholar_id: number,
  scholar_order?: number
) => {
  if (!scholar_order) scholar_order = 1;
  const data = {
    quiz_id: quiz_id,
    scholar_id: scholar_id,
    scholar_order: scholar_order
  };
  let api = APIUrl + `quiz_scholar`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const removeQuizScholar = ( quiz_id: number, id: number) => {
  let api = APIUrl + `quiz_scholar/quiz_scholar_delete`;
  const data = {
    quiz_id: quiz_id,
    id: id
  };
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const addUserAnswer = (
  user_id: number,
  quiz_id: number,
  question_id: number,
  duration: number,
  option_answer: number
) => {
  const data = {
    user_id: user_id,
    quiz_id: quiz_id,
    question_id: question_id,
    duration: duration,
    option_answer: option_answer
  };
  let api = APIUrl + `quiz/add_user_answer`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const startDiscussion = (discussion_id: number, start_date: Date) => {
  const data = {
    discussion_id: discussion_id,
    start_date: start_date
  };
  let api = APIUrl + `discussion/update_discussion_start`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const stopDiscussion = (discussion_id: number) => {
  const data = {
    discussion_id: discussion_id
  };
  let api = APIUrl + `discussion/update_discussion_stop`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const updateProblemPicked = (
  problem_id: number,
  picked_by: number,
  update_date: Date
) => {
  const data = {
    problem_id: problem_id,
    picked_by: picked_by,
    update_date: update_date
  };
  let api = APIUrl + `problem/update_problem_picked`;
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const get_attendance = (data) => {
  let api = APIUrl + `attendance/getattendance`;
   
  return http.post(api, data).then((response) => {
    return response;
  });
};
export const updateProblemResolved = (
  problem_id: number,
  resolved_by: number,
  update_date: Date,
  board_data: string
) => {
  const data = {
    problem_id: problem_id,
    resolved_by: resolved_by,
    update_date: update_date,
    board_data: board_data
  };
  let api = APIUrl + `problem/update_problem_resolved`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const addStudentDiscussion = (
  discussion_id: number,
  student_id: number,
  joining_date: Date
) => {
  const data = {
    discussion_id: discussion_id,
    student_id: student_id,
    joining_date: joining_date
  };
  let api = APIUrl + `student_discussion`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

export const addProblem = (
  topic: string,
  file_url: string,
  uploaded_by: number,
  update_date: Date,
  discussion_id: number
) => {
  const data = {
    topic: topic,
    file_url: file_url,
    uploaded_by: uploaded_by,
    update_date: update_date,
    discussion_id: discussion_id
  };
  let api = APIUrl + `problem`;
  return http.post(api, data).then((response) => {
    return response;
  });
};

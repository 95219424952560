import React, { useEffect, useState, Fragment } from 'react';
import { DiscussionBoard } from '../discussion/board';
import { useParams } from 'react-router-dom';
import logo from '../../assets/logo.png';
import './style.css';
import socketIOClient from 'socket.io-client';
import { ENDPOINT, PATH } from '../../env';
import { Drawer, makeStyles } from '@material-ui/core';
import { ProblemCreate } from '../problem/ProblemCreate';
import { DiscussionProblem } from '../problem/types';
import { ProblemList } from '../problem/ProblemList';
import { useRefresh } from 'react-admin';
import { toast } from 'react-toastify';

interface RouteParams {
  id: number;
}

const socket = socketIOClient(ENDPOINT, {
  path: PATH
});

const myStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 350
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 0,
    width: 650,
    maxWidth: '100%',
    paddingBottom: 50
  }
}));

export const StudentDiscussion = (props) => {
  const refresh = useRefresh();
  const { id } = useParams<RouteParams>();
  const [collapseProblem, setCollapseProblem] = useState<boolean>(true);
  const [showUploadProblemView, setShowUploadProblemView] =
    useState<boolean>(false);
  const [hasBoardPermission, setHasBoardPermission] = useState<boolean>(false);
  const [isStudentProblem, setIsStudentProblem] = useState<boolean>(false);
  const setIsConnected = useState<boolean>(false)[1];
  const [selectedProblem, setSelectedProblem] = useState<
    DiscussionProblem | undefined
  >(undefined);
  const [showProblems, setShowProblems] = useState<boolean>(false);
  const myclasses = myStyles({});
  const userIdStr = localStorage.getItem('userid');
  const userId = userIdStr ? parseInt(userIdStr) : 0;
  const roleName = localStorage.getItem('role');
  const referenceIdStr = localStorage.getItem('reference_id');
  const referenceId = referenceIdStr ? parseInt(referenceIdStr) : 0;
  const team_name = `TEAM_DISCUSSION_${id}`;
  const isPicked = selectedProblem && selectedProblem.is_picked == 1;
  const isResolved = selectedProblem && selectedProblem.is_resolved == 1;
  const isUploaded =
    selectedProblem &&
    selectedProblem.is_picked == 0 &&
    selectedProblem.is_resolved == 0;

  useEffect(() => {
    console.log('USE Effect');
   // initSocketConnection();
    return () => {};
  }, []);

  const OnAllowRevokeStudentAccess = (data) => {
    setHasBoardPermission(
      parseInt(data) == referenceId && roleName == 'student'
    );
  };
  const onProblemPicked = (data) => {
    setSelectedProblem(data);
    setCollapseProblem(false);
    setIsStudentProblem(false);
    toast.success('Problem picked successfully', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'dark'
    });
  };

  const onFacultyShowHideProblem = (data) => {
    setSelectedProblem(data.problem);
    setCollapseProblem(data.collapseProblem);
    setIsStudentProblem(data.isPicked == 0 || data.isResolved == 1);
  };

  const onFacultyShowProblemDetail = (data) => {
    if (selectedProblem && data.id == selectedProblem.id) return;
    setSelectedProblem(data);
    setCollapseProblem(false);
    setIsStudentProblem(data.isPicked == 0 || data.isResolved == 1);
  };

  const onProblemResolved = () => {
    setSelectedProblem(undefined);
    setCollapseProblem(true);
    setIsStudentProblem(true);
    toast.success('Problem resolved successfully', {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: 'dark'
    });
  };

  const onShowHideProblem = () => {
    setCollapseProblem(!collapseProblem);
    if (hasBoardPermission) {
      const data = {
        team_name: team_name,
        problem: selectedProblem,
        collapseProblem: !collapseProblem
      };
      socket.emit('on-student-show-hide-problem', data);
    }
  };

  const onUploadProblem = () => {
    setShowUploadProblemView(true);
  };

  const joinTeam = () => {
    console.log(socket, '====socket===');
    if (!socket) return;
    const data = {
      team_name: team_name,
      member: {
        user_id: userId
      }
    };
    console.log(data);
    socket.emit('join-team', data);
  };

  const initSocketConnection = () => {
    console.log('initSocketConnection');
    joinTeam();
    setupSocketListeners();
    socket.on('welcome', (data) => {
      console.log(data, '===Welcome Student===');
    });
  };

  const setupSocketListeners = () => {
    if (!socket) return;
    socket.on('reconnect', onReconnection);
    socket.on('disconnect', onClientDisconnected);
    socket.on('on-allow-revoke-student', OnAllowRevokeStudentAccess);
    socket.on('on-problem-picked', onProblemPicked);
    socket.on('on-problem-resolved', onProblemResolved);
    socket.on('on-show-problem-detail', onFacultyShowProblemDetail);
    socket.on('on-problem-show-hide', onFacultyShowHideProblem);
  };

  const onClientDisconnected = () => {
    console.log(
      'Connection Lost from server please check your connection.',
      'Error!'
    );
    setIsConnected(false);
  };

  const onReconnection = () => {
    setIsConnected(true);
    joinTeam();
  };

  const onCancel = () => {
    if (showUploadProblemView) setShowUploadProblemView(false);
    if (showProblems) setShowProblems(false);
  };

  const onViewProblems = () => {
    setShowProblems(true);
  };

  const onShowProblemDetail = (problem: DiscussionProblem) => {
    if (selectedProblem && problem.id == selectedProblem.id) return;
    setSelectedProblem(problem);
    setShowProblems(false);
    setCollapseProblem(false);
    setIsStudentProblem(true);
    if (hasBoardPermission) {
      const data = {
        team_name: team_name,
        problem: problem,
        collapseProblem: false
      };
      socket.emit('on-student-show-problem-detail', data);
    }
  };

  return (
    <Fragment>
      <div className='student-container'>
        <img src={logo} className='main-logo' />
        <DiscussionBoard
          discussionProblem={selectedProblem}
          discussionId={id}
          canViewProblemDetail={collapseProblem}
          hasBoardPermission={hasBoardPermission}
        />
        <div className='button-container'>
          {(isUploaded ||
            !isPicked ||
            isResolved ||
            hasBoardPermission ||
            isStudentProblem) && (
            <>
              <input
                className='theme-button blue'
                type='button'
                value='View Problems'
                onClick={onViewProblems}
              />
              {selectedProblem && (
                <input
                  type='button'
                  className='theme-button blue'
                  value='Show/Hide Problem'
                  onClick={onShowHideProblem}
                />
              )}
            </>
          )}
          {(isUploaded || !isPicked || isResolved || isStudentProblem) && (
            <input
              className='theme-button blue'
              type='button'
              value='Upload Problem'
              onClick={onUploadProblem}
            />
          )}
        </div>
      </div>
      <Drawer
        anchor='right'
        onClose={onCancel}
        classes={{
          paper: myclasses.drawerPaper
        }}
        open={showUploadProblemView || showProblems}
      >
        {showUploadProblemView && (
          <ProblemCreate
            {...props}
            discussionId={id}
            onClose={onCancel}
            onCancel={onCancel}
            basePath='/problem'
          />
        )}
        {showProblems && (
          <ProblemList
            {...props}
            discussionId={id}
            onShowProblemDetail={(problem) => onShowProblemDetail(problem)}
            onClose={onCancel}
            onCancel={onCancel}
            basePath='/problem'
          />
        )}
      </Drawer>
    </Fragment>
  );
};

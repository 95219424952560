import React, { FC } from 'react';
import { QuestionItemProps } from './types';
import './style.css';

export const QuestionItem: FC<QuestionItemProps> = ({
  question,
  addQuestion,
  removeQuestion
}) => {
  return (
    <div className='question-item'>
      <img className='question-img' src={question.question_img_url} />
      {addQuestion && (
        <button
          className='btn-add'
          type='button'
          onClick={() => {
            addQuestion(question.question_id);
          }}
        >
          Add
        </button>
      )}
      {removeQuestion && (
        <button
          className='btn-add'
          type='button'
          onClick={() => {
            removeQuestion(question.question_id);
          }}
        >
          Remove
        </button>
      )}
    </div>
  );
};

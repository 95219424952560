import React, { FC, useState } from 'react';
import { StartQuestionProps } from './types';
import './style.css';

export const StartQuestion: FC<StartQuestionProps> = ({
  quizId,
  quiz,
  startQuestion,
  displayQuizDetails,
  moveToQuestion
}) => {
  const [isDisplayDetails, setIsDisplayDetails] = useState<boolean>(false);
  const [questionNumber, setQuestionNumber] = useState<any>(1);
  return (
    <div className='start-question-container'>
      {quiz && <span className='quiz-name'>{quiz.name}</span>}
      {quiz && <span className='quiz-description'>{quiz.description}</span>}
      {quiz && isDisplayDetails && <img src={quiz.quiz_detail_image} className="quiz-details-image"/>}
      <div className="start-question-actions">
        <input type='button' value='Start Quiz' className="theme-button blue" onClick={() => {
          setIsDisplayDetails(false);
          startQuestion();
        }} />
        <input type='button' value='Quiz Details' className="theme-button blue" onClick={() => {
          setIsDisplayDetails(true);
          displayQuizDetails();
        }} />
      </div>
      <div>
      <input type='text' style={{padding:'10px', fontSize:'18px'}} value={questionNumber} onChange={(e) => {setQuestionNumber(e.target.value)}} />
      <input type='button' value='Move To Question' className="theme-button blue" onClick={() => {
          setIsDisplayDetails(false);
          moveToQuestion(questionNumber);
        }} />
      </div>
    </div>
  );
};

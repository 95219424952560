import React, { FC } from 'react';
import { ScholarListProps } from './types';
import { map } from 'lodash';
import { ScholarItem } from './scholar-item';
import './style.css';

export const ScholarList: FC<ScholarListProps> = ({
  scholars,
  add,
  remove
}) => {
  return (
    <div className='question-result-container'>
      {map(scholars, (item: any) => {
        return (
          <ScholarItem
            add={add}
            remove={remove}
            scholar={item}
          />
        );
      })}
    </div>
  );
};

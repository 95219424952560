import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';
import { useEffect } from 'react';
import * as apiClient from '../../common/apiClient';
import socketIOClient from 'socket.io-client';
import { ENDPOINT, PATH } from '../../env';
import { QuestionModel, QuestionScore, QuizModel, UserScore } from '../types';
import { times } from 'lodash';
import { useHistory } from 'react-router-dom';
import { QuestionLeaderBoard } from '../presentation/question-leaderboard';
import logo from '../../assets/logo.png';
import { UserScoreBoard } from '../presentation/user-score-board';
import { QuestionView } from '../presentation/question';
import { QuestionExplanation } from '../presentation/question-explanation';

interface RouteParams {
  id: number;
}

const socket = socketIOClient(ENDPOINT, {
  path: PATH
});

export const StudentPresentation = () => {
  const history = useHistory();
  const { id } = useParams<RouteParams>();
  const [showIntro, setShowIntro] = useState(true);
  const [quiz, setQuiz] = useState<QuizModel | null>(null);
  const setIsConnected = useState<boolean>(false)[1];
  const [timerElapsed, setTimerElapsed] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [showScoreBoard, setShowScoreBoard] = useState<boolean>(false);
  const [showQuestion, setShowQuestion] = useState<boolean>(false);
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const [userAnswer, setUserAnswer] = useState<number>(0);
  const [questionStartTime, setQuestionStartTime] = useState<Date | undefined>(
    undefined
  );
  const [question, setQuestion] = useState<QuestionModel | undefined>(
    undefined
  );
  const [questionScoreList, setQuestionScoreList] = useState<QuestionScore[]>(
    []
  );
  const [quizUserAnswerList, setQuizUserAnswerList] = useState<UserScore[]>([]);
  const userIdStr = localStorage.getItem('userid');
  const userId = userIdStr ? parseInt(userIdStr) : 0;
  const team_name = `TEAM_${id}`;
  const options = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const questionOptions: number = question?.option_count
    ? question?.option_count
    : 0;
  const joinTeam = () => {
    console.log(socket, '====socket===');
    if (!socket) return;
    const data = {
      team_name: team_name,
      member: {
        user_id: userId
      }
    };
    console.log(data);
    socket.emit('join-team', data);
  };

  const initSocketConnection = () => {
    console.log('initSocketConnection');
    joinTeam();
    setupSocketListeners();
    socket.on('welcome', (data) => {
      console.log(data, '===Welcome Student===');
    });
  };

  const onClientDisconnected = () => {
    console.log(
      'Connection Lost from server please check your connection.',
      'Error!'
    );
    setIsConnected(false);
  };

  const onReconnection = () => {
    setIsConnected(true);
    joinTeam();
  };

  const onQuestionStartRecieved = (data) => {
    console.log(data);
    setShowScoreBoard(false);
    setShowTimer(true);
    setQuestion(undefined);
    setQuestion(data);
    setQuestionStartTime(new Date());
    setTimerElapsed(false);
    setShowExplanation(false);
    setShowQuestion(true);
    setShowResult(false);
    setShowIntro(false);
  };

  const onQuestionStopRecieved = (data) => {
    console.log(data);
    setShowScoreBoard(false);
    setShowTimer(false);
    setQuestion(data);
    setQuestionStartTime(undefined);
    setShowExplanation(false);
    setTimerElapsed(true);
    setUserAnswer(0);
    setShowResult(false);
    setShowIntro(false);
  };
  const onShowResult = (data) => {
    setQuestionScoreList(data);
    setShowResult(true);
    setShowQuestion(false);
    setShowExplanation(false);
    setShowScoreBoard(false);
    console.log(data, 'show result');
    setShowIntro(false);
  };

  const onBackQuestion = (data) => {
    setQuestion(data);
    setShowResult(false);
    setShowQuestion(true);
    setShowExplanation(false);
    setShowScoreBoard(false);
    setShowIntro(false);
    console.log(data);
  };

  const onShowScoreBoard = (data) => {
    setQuizUserAnswerList(data);
    setShowResult(false);
    setShowQuestion(false);
    setShowExplanation(false);
    setShowScoreBoard(true);
    setShowIntro(false);
    console.log(data);
  };

  const onTimerComplete = () => {
    setShowScoreBoard(false);
    setShowTimer(false);
    setTimerElapsed(true);
    setShowResult(false);
    setShowQuestion(true);
    setShowExplanation(false);
    setShowIntro(false);
  };

  const onExplanationClick = () => {
    setShowExplanation(true);
    setTimerElapsed(true);
    setShowResult(false);
    setShowQuestion(false);
    setShowScoreBoard(false);
    setShowIntro(false);
  };


  const onQuestionShowHide = (data) => {
    setShowExplanation(data);
    setTimerElapsed(true);
    setShowResult(false);
    setShowQuestion(!data);
    setShowScoreBoard(false);
    setShowIntro(false);
  };

  const setupSocketListeners = () => {
    if (!socket) return;
    socket.on('reconnect', onReconnection);
    socket.on('disconnect', onClientDisconnected);
    socket.on('on-question-start', onQuestionStartRecieved);
    socket.on('on-question-stop', onQuestionStopRecieved);
    socket.on('on-show-result', onShowResult);
    socket.on('on-back-question', onBackQuestion);
    socket.on('on-show-score-board', onShowScoreBoard);
    socket.on('on-explanation-click', onExplanationClick);
    socket.on('on-question-show-hide', onQuestionShowHide);
  };

  const getQuiz = () => {
    apiClient
      .getQuiz(id)
      .then((res) => {
        console.log(res, '=======Student Quiz =======');
        if (res.status == 'success') {
          setQuiz(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log('USE Effect');
    //initSocketConnection();
    getQuiz();
    return () => { };
  }, []);

  const handleSubmit = () => {
    //call api to submit user response
    if (!questionStartTime || !question || userAnswer === 0) return;
    const diff = new Date().getTime() - questionStartTime.getTime();
    const durationMilliSeconds = Math.abs(diff);
    apiClient
      .addUserAnswer(
        userId,
        id,
        question.question_id,
        durationMilliSeconds,
        userAnswer
      )
      .then((res) => {
        if (res.status == 'success') {
          console.log('success');
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setQuestionStartTime(undefined);
    setTimerElapsed(true);
    setUserAnswer(0);
  };
  return (
    <div className='student-presentation-container presentation-container'>
      <img src={logo} className='main-logo' />
      {showIntro && <div className="quiz-intro">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/6KPp_GMDQYo?rel=0&loop=1&autoplay=1&controls=0"
          title="YouTube video player" allow="accelerometer; loop;autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
      </div>}
      {question && showQuestion && (
        <div className='question-container'>
          <div className='question-images-container'>
            <QuestionView
              question={question}
              onTimerComplete={onTimerComplete}
              showTimer={showTimer}
            />
          </div>
          {!timerElapsed && (
            <>
              <fieldset id='option-group' className='question-group'>
                {times(questionOptions, (index) => {
                  return (
                    <div
                      className='question-option'
                      onClick={() => setUserAnswer(index + 1)}
                    >
                      <input
                        type='radio'
                        checked={userAnswer == index + 1}
                        value={index + 1}
                        name='option-group'
                      />
                      <label>{options[index]}</label>
                    </div>
                  );
                })}
              </fieldset>
              <div className='btn-submit'>
                <input
                  type='button'
                  value='Submit'
                  className='theme-button blue'
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}
        </div>
      )}
      {showResult && questionScoreList && (
        <QuestionLeaderBoard questionScoreList={questionScoreList} />
      )}
      {showScoreBoard && quizUserAnswerList && (
        <UserScoreBoard quizUserAnswerList={quizUserAnswerList} />
      )}
      {showExplanation && question && (
        <div className='student-explanation-container'>
          {/* <div className='student-explanation-overlay'></div> */}
          <QuestionExplanation question={question} userType="student" />
        </div>
      )}
      <div
        className='exit-presentation theme-button'
        onClick={() => {
          history.push(`/quiz`);
        }}
      >
        Close
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { QuestionModel } from '../types';
import { QuestionListProps } from './types';
import { map } from 'lodash';
import { QuestionItem } from './question-item';
import './style.css';

export const QuestionList: FC<QuestionListProps> = ({
  questions,
  addQuestion,
  removeQuestion
}) => {
  return (
    <div className='question-result-container'>
      {map(questions, (item: QuestionModel) => {
        return (
          <QuestionItem
            addQuestion={addQuestion}
            removeQuestion={removeQuestion}
            question={item}
          />
        );
      })}
    </div>
  );
};

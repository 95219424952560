import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  required
} from 'react-admin';
import { FormToolbar } from '../../common/FormToolbar';

export const DiscussionCreate = (props) => {
  const initialValues = { created_by: localStorage.getItem('userid') };
  return (
    <Create undoable={false} title='Discussion Edit' {...props}>
      <SimpleForm
        redirect='list'
        toolbar={<FormToolbar {...props} />}
        variant='outlined'
        initialValues={initialValues}
      >
        <TextInput
          source='topic'
          label='Topic'
          fullWidth={true}
          validate={[required()]}
        />
        <ReferenceInput source='class_id' reference='genere' fullWidth={true}>
          <SelectInput optionText='class_name' />
        </ReferenceInput>
        <ReferenceInput
          source='subject_id'
          reference='subject'
          fullWidth={true}
        >
          <SelectInput optionText='subject' />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

import React from 'react';
import { Edit, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required } from 'react-admin';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";

export const TeacherSubjectCreatePopup = props => {
    const defaultValues = { employee_id: props.employee_id, };

    const handleSubmit = (formdata) => {
        let data = {
            employee_id: props.employee_id,
            subject_id: formdata.subject_id,
        };
        apiClient.addEntiyRecord("teachersubject", data).then(() => {
            props.onClose();
        });
    }
    return (
        <Create title="Teacher Subject Mapping" {...props}>
            <SimpleForm

                toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                initialValues={defaultValues}
                variant="outlined">
                <ReferenceInput perPage={500} source="subject_id" reference="subject" fullWidth={true} link={false}>
                    <SelectInput optionText="subject" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}
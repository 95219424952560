import React, { FC } from 'react';
import './style.css';
import { map, max } from 'lodash';
import { VerticalBarChartProps } from './types';

export const VerticalBarChart: FC<VerticalBarChartProps> = ({ data }) => {
  const maxCount = max(map(data, 'value'));
  return (
    <div className='container'>
      <div className='main-container'>
        {data.map(({ value, isCorrect }, i) => {
          const barHeight = `${(value / maxCount) * 100}%`;
          return (
            <div className='bar-chart-container' key={i}>
              <span className='number'>{value}</span>
              <div
                className={
                  isCorrect ? 'make-correct-bar' : 'make-incorrect-bar'
                }
                style={{ height: barHeight }}
              />
            </div>
          );
        })}
      </div>
      <div className='black-line' />
      <div className='option-container'>
        {data.map(({ optionText }) => {
          return (
            <span className='option-text' key={optionText}>
              {optionText}
            </span>
          );
        })}
      </div>
    </div>
  );
};

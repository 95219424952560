import { Link, Button } from '@material-ui/core';
import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton, useRefresh
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import * as apiClient from '../../common/apiClient';

const StartButton = (props) => {
  const quizId = props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const handleClick = () => {
    apiClient
      .startQuiz(Number(quizId), new Date())
      .then((res) => {
        if (res.status == 'success') {
          history.push(`/presentation/${quizId}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return <input type='button' value='Start' className="theme-button blue size-medium" onClick={handleClick} />;
};

const StopButton = (props) => {
  const quizId = props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const refresh = useRefresh();
  const handleClick = () => {
    apiClient
      .stopQuiz(Number(quizId))
      .then((res) => {
        if (res.status == 'success') {
          refresh();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (props.record && props.record.is_live == "1")
    return <input type='button' value='Stop' className="theme-button blue size-medium" onClick={handleClick} />;
  else
    return null;
};

const MapQuestionButton = (props) => {
  const quizId = props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const handleClick = () => history.push(`/quiz-question/${quizId}`);
  return <input type='button' value='Map Questions' className="theme-button blue size-medium" onClick={handleClick} />;
};

const MapStuidentButton = (props) => {
  const quizId = props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const handleClick = () => history.push(`/quiz-scholar/${quizId}`);
  return <input type='button' value='Map Students' className="theme-button blue size-medium" onClick={handleClick} />;
};

const JoinQuiz = (props) => {
  const quizId = props && props.record && props.record.id ? props.record.id : 0;
  const history = useHistory();
  const handleClick = () => history.push(`/student-presentation/${quizId}`);
  return <input type='button' value='Join' className="theme-button blue size-medium" onClick={handleClick} />;
};

export const QuizList = (props) => {
  const role = localStorage.getItem('role');
  const isStudent = role == 'student';
  var filter;
  if (isStudent) {
    filter = { is_live: 1 };
  }
  return (
    <List title='List of Quiz' {...props} sort={{ field: 'id', order: 'DESC' }} filter={filter}>
      <Datagrid rowClick={() => { }}>
        {!isStudent && <EditButton />}
        <TextField source='name' />
        <TextField source='description' />
        <TextField source='is_live' label="Is Live" />
        <StopButton />
        {/* {!isStudent && <TextField source='live_stream_url'/>}
        {!isStudent && <TextField source='youtube_video_id' />} */}
        <ReferenceField source='class_id' reference='genere' link={false}>
          <TextField source='class_name' />
        </ReferenceField>
        <ReferenceField source='subject_id' reference='subject' link={false}>
          <TextField source='subject' />
        </ReferenceField>
        {!isStudent && <StartButton />}
        {!isStudent && <MapQuestionButton />}
        {!isStudent && <MapStuidentButton />}
        {isStudent && <JoinQuiz />}
      </Datagrid>
    </List>
  );
};

import React from 'react';
import { List, Datagrid, TextField, ReferenceField,ReferenceInput, SelectInput,
     BooleanField, DateField, Filter, SearchInput } from 'react-admin';

const MyFilters = props => (
    <Filter {...props} variant="outlined" >
        <SearchInput source="scholar_no" alwaysOn />
        <SearchInput source="name" alwaysOn />
        <SearchInput source="alert_mobile_no" alwaysOn />
        {/* <ReferenceInput alwaysOn variant="outlined" label="Class" source="class_id=" reference="genere"
                sort={{ field: 'numeric_equivalent', order: 'ASC' }} perPage={500} >
                <SelectInput optionText="class_name" />
            </ReferenceInput> */}
    </Filter>
);


export const ScholarList = props => {
    return (
        <React.Fragment>
            <List title="List of Scholars" {...props} sort={{ field: 'id', order: 'DESC' }} filters={<MyFilters />}>
                <Datagrid rowClick="edit">
                    {/* <TextField source="id" /> */}
                    <TextField source="scholar_no" label="Scholar No." />
                    <TextField source="session" label="Academic Session" />
                    <TextField source="name" label="Scholar Name" />
                    <TextField source="dob" label="Date of birth" />
                    <TextField source="gender" label="Gender" />
                    <TextField source="father" label="School" />
                    <TextField source="mother" label="City" />
                    <TextField source="alert_mobile_no" label="SMS Alert Mobile" />
                    <ReferenceField source="class_id" reference="genere" link={false}>
                        <TextField source="class_name" />
                    </ReferenceField>
                    <ReferenceField source="section_id" reference="section" link={false}>
                        <TextField source="section" />
                    </ReferenceField>
                    <BooleanField source="is_active" label="Is Active" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
}
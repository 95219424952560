import React from 'react';
import { List, Datagrid, TextField, Filter, TextInput } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { lightTheme } from '../../layout/themes';
import './style.css';

const MyFilters = (props) => (
  <Filter {...props} variant='outlined'>
    <TextInput label='Scholar No.' source='scholar_no' alwaysOn />
    <TextInput label='Name' source='name' alwaysOn />
  </Filter>
);

const AllowAccess = ({ onAllowAccess, ...props }) => {
  const studentId =
    props && props.record && props.record.id ? props.record.id : 0;
  return (
    <input
      type='button'
      value='Allow'
      className='theme-button blue size-medium'
      onClick={() => onAllowAccess(studentId)}
    />
  );
};

export const StudentList = ({ discussionId, onAllowAccess, ...props }) => {
  return (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
      <div className='student-list-container'>
        <List
          actions={<></>}
          exporter={false}
          bulkActionButtons={false}
          resource='discussion_scholar'
          basePath='/discussion_scholar'
          title='List of Scholars'
          {...props}
          filters={<MyFilters />}
          filter={{ "discussion_id=": discussionId }}
        >
          <Datagrid rowClick={false}>
            <TextField source='scholar_no' label='Scholar No.' />
            <TextField source='name' label='Scholar Name' />
            <AllowAccess onAllowAccess={onAllowAccess} />
          </Datagrid>
        </List>
      </div>
    </ThemeProvider>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { HorizontalBarChart } from '../horizontal-bar-chart';
import { UserScoreBoardProps } from './types';
import { HorizontalBarChartData } from '../horizontal-bar-chart/types';
import { max, map } from 'lodash';
import { UserScore } from '../../types';

export const UserScoreBoard: FC<UserScoreBoardProps> = ({
  quizUserAnswerList
}) => {
  const chartData: HorizontalBarChartData[] = [];
  const maxWidth: number = 80;
  const maxScore = max(
    map(quizUserAnswerList, (item: UserScore) => {
      return Number(item.score);
    })
  );

  for (let i = 0; i < quizUserAnswerList.length; i++) {
    chartData.push({
      name: quizUserAnswerList[i].username,
      value: Number(quizUserAnswerList[i].score),
      width:
        maxScore == Number(quizUserAnswerList[i].score)
          ? maxWidth
          : Math.round(
              (maxWidth / maxScore) * Number(quizUserAnswerList[i].score)
            )
    });
  }
  return (
    <div className='leaderboard-data'>
      <HorizontalBarChart data={chartData} />
    </div>
  );
};

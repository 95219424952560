import React, { useState, useCallback, useEffect } from 'react';
import {
    useRefresh, ReferenceField,
    Edit, TabbedForm, FormTab, TextInput, NumberInput, BooleanInput, ReferenceInput,
    SelectInput, ReferenceManyField, required, Datagrid, TextField, maxLength, minLength
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles, Drawer } from '@material-ui/core';
import CDeleteButton from "../../common/CDeleteButton";
import { TeacherSubjectCreatePopup } from "./TeacherSubjectCreatePopup";
import { FormToolbar } from "../../common/FormToolbar";
import { TeacherClassCreatePopup } from "./TeacherClassCreatePopup";

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 350,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 0,
        width: 650,
        maxWidth: "100%",
        paddingBottom: 50
    },
}));

export const EmployeeEdit = props => {
    const myclasses = myStyles({});
    const refresh = useRefresh();
    //STATE
    const [addMapping, setAddMapping] = useState(false);
    const [addClass, setAddClass] = useState(false);


    // PRIVATE METHODS
    const closeMappingForm = () => {
        setAddMapping(false);
        refresh();
    }
    const closeClassForm = () => {
        setAddClass(false);
        refresh();
    }
    const onCancel = () => {
        setAddMapping(false);
        setAddClass(false);
    }
    return (
        <React.Fragment>
            <Edit title="Edit Employee" {...props}>
                <TabbedForm warnWhenUnsavedChanges toolbar={<FormToolbar {...props} />}
                    variant="outlined">
                    <FormTab {...props} label="Employee Details">
                        <TextInput source="name" label="Employee Name" fullWidth={true} validate={[required()]} />
                        <NumberInput source="mobile_no" label="Mobile No" fullWidth={true} validate={[required(), maxLength(10), minLength(10)]} />
                        <SelectInput source="faculty_type" choices={[
                            { id: 'Principal', name: 'Principal' },
                            { id: 'Teacher', name: 'Teacher' },
                            { id: 'Office Executive', name: 'Office Executive' },
                        ]} label="Employee Type" validate={[required()]} fullWidth={true} />
                        <TextInput source="designation" label="Designation" fullWidth={true} validate={[required()]} />
                        <BooleanInput source="is_active" label="Is Active" fullWidth={true} validate={[required()]} />
                    </FormTab>
                    <FormTab {...props} label="Subjects">
                        <Button variant="contained" onClick={() => { setAddMapping(true); }}
                            color="primary">
                            Add Subject
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="teachersubject"
                            target="employee_id">
                            <Datagrid>
                                <CDeleteButton {...props} />
                                <TextField source="subject" label="Subject" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                    {/* <FormTab {...props} label="Classes">
                        <Button variant="contained" onClick={() => { setAddClass(true); }}
                            color="primary">
                            Assign Class
                            </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="teacher_class_section"
                            target="employee_id">
                            <Datagrid>
                                <CDeleteButton {...props} />
                                <TextField source="class_name" label="Class" />
                                <TextField source="section" label="Section" />
                                <TextField source="subject" label="Subject" />
                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab> */}
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{
                    paper: myclasses.drawerPaper,
                }}
                open={addMapping || addClass}>
                {addMapping && <TeacherSubjectCreatePopup
                    onClose={closeMappingForm}
                    onCancel={onCancel}
                    {...props}
                    basePath="/teachersubject"
                    employee_id={props.id} />}
                {addClass && <TeacherClassCreatePopup
                    onClose={closeClassForm}
                    onCancel={onCancel}
                    {...props}
                    basePath="/teacher_class_section"
                    employee_id={props.id} />}
            </Drawer>
        </React.Fragment>
    );
}
import React from 'react';
import { List, Datagrid, TextField, BooleanField, Filter, SearchInput } from 'react-admin';

const MyFilters = props => (
    <Filter {...props} variant="outlined" >
        <SearchInput  source="name" alwaysOn />
        <SearchInput  source="mobile_no" alwaysOn />
    </Filter>
);



export const EmployeeList = props => {
    return (
        <React.Fragment>
            <List title="List of Employees" sort={{ field: 'id', order: 'DESC' }}
                {...props} filters={<MyFilters />}>
                <Datagrid rowClick="edit">
                    {/* <TextField source="id" /> */}
                    <TextField source="name" label="Employee Name" />
                    <TextField source="mobile_no" label="Mobile No" />
                    <TextField source="faculty_type" label="Employee Type" />
                    <TextField source="designation" label="Designation" />
                    <BooleanField source="is_active" label="Is Active" />
                </Datagrid>
            </List>
        </React.Fragment>
    );
}
import polyglotI18nProvider from 'ra-i18n-polyglot';
import React, { useEffect, useState } from 'react';
import { Admin, EditGuesser, ListGuesser, Resource } from 'react-admin';
import './App.css';
import authProvider from './authProvider';
import { APIUrl } from './env';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { Layout, Login } from './layout';
import { MyDataProvider } from './providers/MyDataProvider';
import customRoutes from './routes';
import classes from './screens/classes';
import employee from './screens/employee';
import scholar from './screens/scholars';
import sections from './screens/sections';
import quiz from './screens/quiz';
import discussion from './screens/discussion';
import question from './screens/question';
import subject from './screens/subject';
import themeReducer from './themeReducer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Attendance } from './screens/uploadattendance1/AttendanceList';


const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === 'fr') {
    return import('./i18n/fr').then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, 'en');

const App = () => {
  const [dataProvider, setDataProvider] = useState({});

  useEffect(() => {
    let provider = MyDataProvider(APIUrl);
    setDataProvider(provider);
  }, []);

  return (
    <>
      <Admin
        title=''
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        <Resource name='classgroup' list={ListGuesser} edit={EditGuesser} />
        <Resource name='role' list={ListGuesser} edit={EditGuesser} />
        <Resource name='scholar' {...scholar} />
        <Resource name='genere' {...classes} />
        <Resource name='section' {...sections} />
        <Resource name='employee' {...employee} />
        <Resource name='subject' {...subject} />
        <Resource name='teachersubject' list={ListGuesser} edit={EditGuesser} />
        <Resource name='changepassword' list={ListGuesser} edit={EditGuesser} />
        <Resource name='user' />
        <Resource name='quiz' {...quiz} />
        <Resource name='discussion' {...discussion} />
        <Resource name='question' {...question} />
        <Resource name='problem' list={ListGuesser} edit={EditGuesser} />
        <Resource name='uploadattendance' {...Attendance} />
        <Resource
          name='discussion_scholar'
          list={ListGuesser}
          edit={EditGuesser}
        />
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
